import gql from 'graphql-tag';

// export const LOGIN_MUTATE = gql`
// mutation login($Email:String,$Password:String){
//   login(Email:$Email,Password:$Password){
//     jwt
//     Id
//     Id2
//     Id4
//     TqId
//     Alias
//     Estado
//     Placa
//     Grupo
//     Empresa
//     TZ
//     UhValor
//     EmpresaNombre
//     GrupoNombre
//     usuarioengrupos {
//       LtMovil
//       grupo {
//         TgId
//         TgNombre
//         TgValor
//         TgEmpresa
//       }
//     }
//   }
// }
// `;

export const Login = gql`mutation Login($input: loginInput!) {  
    login(input: $input) {
        __typename
        body
        error
        message
        status
    }
  }`
    ;