<div class="general-cont-home">

    <div class="general-cont-home-padding">

        <div class="general  ">

            <!-- <div class="navabar">
                <app-navbar-left (size_page)="size_page_Recive($event)"(changuePage)="changuePageRecive($event)"></app-navbar-left>
            </div> -->
            <div [ngClass]="{'cont-interactive': size_page, 'cont-interactive-min': !size_page}" class=" box">


                <router-outlet></router-outlet>


            </div>



            
        </div>
        
    </div>


</div>