import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from "../../environments/environment"

@Injectable({
  providedIn: 'root'
})
export class HistoricService {

  constructor(public http: HttpClient) { }

  zeus: string = environment.zeus;
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  getUsersZeus(query){

    return this.http.get<any>(this.zeus + 'getUsers/'  + JSON.stringify(query) , this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }

  getReportsByDates(report){

    return this.http.get<any>(this.zeus + 'getReportsByDates/'  + JSON.stringify(report) , this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );

  }
getStatus(){

  return this.http.get("assets/motives.json")
  .pipe(
    catchError(this.handleError)
  );
}


  
  getReportScrollZeus( report: any) {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        })
      };
  
      return this.http.get<any>(this.zeus+   "getReports/"  + JSON.stringify({ "pageSize": report.pageSize,"Id": report.Id, "company": report.company}), httpOptions)
        .pipe(
          catchError(this.handleError)
        );
    }
    

  private handleError(error: HttpErrorResponse) {
    console.log(error)

    if (error.error instanceof ErrorEvent) {
      throw error;
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
