import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import API, { graphqlOperation } from "@aws-amplify/api";
import { Login } from "../graphql/login";

@Injectable({
  providedIn: 'root'
})
export class LoginService implements OnInit {

  zeus = environment.zeus;
  userToken: string;

  constructor(public http: HttpClient) {
    this.leerToken();
  }

  ngOnInit() {
    //console.log("OnInit");
  }



  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  async Login(credentials): Promise<any> {
    //console.log("params login", credentials)
    const response = (await API.graphql(
      graphqlOperation(Login, credentials)
    )) as any;
    //console.log("responde params login", response)
    return <any>response.data.login;
  };

  loginService(credentials: any) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.zeus + 'login', credentials, httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  newpass(credentials) {

    return this.http.post<any>(this.zeus + 'confirmForgotPassword', credentials, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );


  }
  forgotPassword(credentials: any) {


    return this.http.post<any>(this.zeus + 'forgotPassword', credentials, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  leerToken() {
    if (localStorage.getItem('auth')) {
      this.userToken = localStorage.getItem('auth');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  estaAutenticado(): boolean {
    if (localStorage.getItem('auth')) {
      return true;
    } else {
      return false;
    }

  }

  private handleError(error: HttpErrorResponse) {

    //console.log(error)
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error);

    } else {
      console.error(
        `Backend returned code ${error}, ` +
        `body was: ${error}`);
      return throwError(
        error
      )
    }
    return throwError(
      error)
  };
}

