import { Component, OnInit } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { ChimeService } from '../../services/chime.service';
import { FileSaverService } from 'ngx-filesaver';
import { PermissionsService } from './../../services/permissions.service';

import Swal from 'sweetalert2';

interface model {
  Id: string,
  dateIso8601: string,
  serverDate: number,
  url: string,
  userId: string
}

@Component({
  selector: 'app-whiteboard',
  templateUrl: './whiteboard.component.html',
  styleUrls: ['./whiteboard.component.css'],
  providers: [FileSaverService]
})
export class WhiteboardComponent implements OnInit {

  models = [];
  dynamo_user = JSON.parse(localStorage.getItem("dynamo_user"));
  notFound: boolean;
  loader: boolean;
  _value: number = 0;
  dataPermission = {};
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }
  constructor(
    private _chimeService: ChimeService,
    private _fileSaverService: FileSaverService,
    private valuePermissions: PermissionsService) { }

  ngOnInit() {
    this.dataPermission = this.valuePermissions.getSpecificModule('VideoLi');
    //console.log("data permission", this.dataPermission);
  }

  gotoLink(url: string) {
    this._chimeService.download(url).subscribe(res => {
      //console.log("resssssssssss", res);
      window.open(url, '_blank');
    }, err => {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${err.status}`
      });
    });
  }

  async onDown(fromRemote: boolean, model: model) {
    if (fromRemote) {
      //console.log("model", model);
      //console.log("model url", model.url);
      this._chimeService.download(model.url).subscribe((res: HttpResponse<any>) => {
        //console.log("res download", res);
        if (res['loaded'] && res['total']) {
          this.value = Math.round(res['loaded'] / res['total'] * 100);
          //console.log("value", this.value);

        }

        if (res['body']) {
          this._fileSaverService.save(res['body'], model['newUrl']);
          // Math.abs((this.value) * -1)
          let timerInterval;
          Swal.fire({
            title: 'Descargando...',
            html: '',
            timer: Math.abs((this.value) * -1),
            timerProgressBar: true,
            willOpen: () => {
              //console.log("will open?¡?",);
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                  if (b) {
                    let queFalta = Swal.getTimerLeft()
                    //console.log("que falta", queFalta);
                  }
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            //console.log("entra al result", result);
            this.value = 0;
            Swal.fire(
              'Completado!',
              'El archivo se ha descargado satisfactoriamente.',
              'success'
            )
          })



        }

      });
      return;
    }
  }

}
