
    <input type="file" #inputFile [formControl]="formControl" [formlyAttributes]="field" accept="image/*"
    (change)="showPreview($event)">
    <div id="imagePrev" class="imagePreview" *ngIf="formControl.value === null || formControl.value === ''" style="width: 50%">
    <img src="assets/no-image/noimage.jpg">
    </div>
    <div *ngIf="imageURL && imageURL !== ''">
      <div id="imagePrev" class="imagePreview" *ngIf="formControl.value !== null && formControl.value !== ''" style="width: 50%; position: relative">
      <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#64d6e2" type="ball-spin-clockwise" name="image" [fullScreen]="false">
      </ngx-spinner>
      <img [src]="imageURL">
      </div>
    </div>

  