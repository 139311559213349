import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthVideocallGuard implements CanActivate {
  constructor(private auth: LoginService, private router: Router) {

  }
  canActivate(): boolean {
    //console.log("entra al guard");
   
    return true;
  }


}


