import gql from 'graphql-tag';

export const GET_MOVIL = gql`
query movil($Id:String){
  movil(Id:$Id) {
    Id    
		Id2
    Id5
    Placa
    Empresa
    TZ
    Estado
    Alias
    EmpresaNombre
    GrupoNombre
    Grupo
    teams {
      GrId
      GrEmpresa
      GrNombre
      GrComentario
    }
  }
}
`;

export const GET_ALL = gql`
query moviles($Empresa:Int,$Placa: String){
  moviles(Empresa:$Empresa,TqId:[51,55],Placa: $Placa) {
    Id
    Grupo
    GrupoNombre
    Placa
    Alias
  }
}
`;

export const ADD_MOVIL = gql`
mutation ($MvId: String,$MvId2: String,$MvTelefono: String,$MvPlaca: String,$MvAlias: String,$MvTipoVehiculo: Int,$MvTipo: Int,$MvSetup: String,$MvTipoInstalacion: Int,$MvParametros: Int,$MvInstalacionFecha: String,$MvFechaCarga: String,$MvMantenimiento: Int, $MvGrupo: Int){
  addMovil(MvId:$MvId,MvId2:$MvId2,MvTelefono:$MvTelefono,MvPlaca:$MvPlaca,MvAlias:$MvAlias,MvTipoVehiculo:$MvTipoVehiculo,MvTipo:$MvTipo,MvSetup:$MvSetup,MvTipoInstalacion:$MvTipoInstalacion,MvParametros:$MvParametros,MvInstalacionFecha:$MvInstalacionFecha,MvFechaCarga:$MvFechaCarga,MvMantenimiento:$MvMantenimiento,MvGrupo:$MvGrupo) {
    MvId
    MvId2
    MvTelefono
    MvPlaca
    MvAlias
    MvTipoVehiculo
    MvTipo
    MvSetup
    MvTipoInstalacion
    MvParametros
    MvInstalacionFecha
    MvFechaCarga
    MvMantenimiento
    MvGrupo
    MvId3
    MvId4
    MvId5
  }
}
`;

export const UPDATE_MOVIL = gql`
mutation($MvId: String,$MvId2: String,$MvGrupo: Int, $MvPlaca: String, $MvAlias: String){
  updateMovil(MvId:$MvId,MvId2: $MvId2,MvGrupo:$MvGrupo, MvPlaca: $MvPlaca, MvAlias: $MvAlias){
    MvId
    MvId2
    MvGrupo
    MvAlias
    MvPlaca
  }
}
`;