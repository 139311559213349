import gql from 'graphql-tag';

export const GET_TEAMS = gql`
query($GrEmpresa: Int){
  teams(GrEmpresa: $GrEmpresa) {
    GrId
    GrEmpresa
    GrNombre
    GrComentario
  }
}
`;

export const ADD_TEAM = gql`
mutation($GrNombre: String,$GrComentario: String,$GrEmpresa: Int){
  addTeam(GrNombre:$GrNombre,GrComentario:$GrComentario,GrEmpresa:$GrEmpresa){
    GrId
    GrNombre
    GrEmpresa
    GrComentario
  }
}
`;