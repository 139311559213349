import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'formly-field-file',
  template: `
    <input type="file" #inputFile [formControl]="formControl" [formlyAttributes]="field" accept="image/*"
    (change)="showPreview($event)">
    <div id="imagePrev" class="imagePreview" *ngIf="formControl.value === null || formControl.value === ''" style="width: 50%">
    <img src="assets/no-image/noimage.jpg">
    </div>
    <div *ngIf="imageURL && imageURL !== ''">
      <div id="imagePrev" class="imagePreview" *ngIf="formControl.value !== null && formControl.value !== ''" style="width: 50%; position: relative">
      <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#64d6e2" type="ball-spin-clockwise" name="image" [fullScreen]="false">
      </ngx-spinner>
      <img [src]="imageURL">
      </div>
    </div>

  `,
})
export class FormlyFieldFile extends FieldType {

  @ViewChild('inputFile', { static: false }) inputFile: ElementRef;


  imageURL;

  constructor(private spinner: NgxSpinnerService,
    private _util: UtilsService) {
    super()
  }

  showPreview(event) {
    this.imageURL = "";
    this.spinner.show('image');
    if (event.target.files[0]) {
      console.log("this", this.formControl);

      const file = (event.target as HTMLInputElement).files[0];
      // this.form.get('avatar').updateValueAndValidity()

      // File Preview
      const reader = new FileReader();

      reader.onload = () => {
        let result = reader.result as string;
        this.imageURL = result;
        let key = this.key;
        this._util.subjectImage.next({ key: key, result: result });
      };
      if (file != null || undefined) {
        this.spinner.hide('image');
        reader.readAsDataURL(file);
      }
    } else {
      this.form.patchValue({
        image: null
      });
      this.imageURL = null;
    }
    this.spinner.hide('image');

  }



}

