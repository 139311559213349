import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse,HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {


  graphql = environment.urlGraph;
  clientId = environment.AzureclientId


  constructor(public http: HttpClient) { }

  getReportsByGroup(token: any, group: any) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups/' + group + '/reports', httpOptions)
      .pipe(
        catchError(this.handleError)
      )
  }
 getGroups(token) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer' + ' ' + token
      })
    };
    return this.http.get<any>('https://api.powerbi.com/v1.0/myorg/groups', httpOptions)
      .pipe(
        catchError(this.handleError)
      )

  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      throw error.error.message;
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }



  getAzureToken() {

    const query = `
  mutation azureToken($clientId: String!){
    azureToken(clientId: $clientId)
}
`;

    let httpOptions = {

      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })


    };
    return this.http.post<any>(this.graphql,
      JSON.stringify({
        query: query,
        variables: {
          clientId: this.clientId
        }
      }), httpOptions)
      .pipe(catchError(this.handleError))
      }
      
      
      
}
