// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlSocket:`wss://api3.azlogica.com:13302/subscriptions`,
  zeus:"https://z3c6126ct9.execute-api.us-east-1.amazonaws.com/dev/",
  // urlGraph : "https://sns4.azlogica.com/graphql",
  urlGraph:'https://api3.azlogica.com/graphql',
  AzureclientId : 'b2505762-3354-4c85-9667-c09a97d64679',
  token : "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IkZFTElQRS5DQVNUSUxMT0BBWkxPR0lDQS5DT00iLCJFbWFpbCI6IkZFTElQRS5DQVNUSUxMT0BBWkxPR0lDQS5DT00iLCJpYXQiOjE1ODc0MDExODd9.kH0oA9-Ah21eOvbmWjD7E--qOxgSUQHSI-NmZie43mo",
  appSync: "https://myattcs7nvcpfogh2jcuhnsof4.appsync-api.us-east-1.amazonaws.com/graphql"

};

/*
 * For easier debugging in dey uque hacvelopment mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
