<div class="general-chat">

    <div class="cont-chat">

        <div class="container-manager" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="500"
            [scrollWindow]="false">

            <div class="general-container">

                <!-- <div class="loader-container" style="text-align: center;">
                    <app-loader></app-loader>
                </div> -->

                <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#64d6e2" type="ball-clip-rotate-multiple" name="sendForm" [fullScreen]="false">
                    <p style="font-size: 16px;color: white" class="fontStyle">Enviando formulario...</p>
                </ngx-spinner>

                <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#64d6e2" type="ball-clip-rotate-multiple" name="loader" [fullScreen]="false">
                    <p style="font-size: 16px;color: white" class="fontStyle">Cargando formulario...</p>
                </ngx-spinner>


                <div class="columns is-vcentered is-mobile is-2-mobile" style="padding: 1%;">

                    <div class="column is-6">

                        <button type="button" class="btn btn-outline-primary" style="text-transform: none;" (click)="navigateBack()"><i class=" fas
                            fa-arrow-circle-left"></i> Regresar
                        </button>

                    </div>


                    <div class="column is-6 has-text-right">
                        <button type="button" class="button is-danger" (click)="closeSesion()"><i class="fas fa-power-off mr-1"></i> Salir </button>

                    </div>

                </div>


                <div class="columns">

                    <div *ngIf="dataForm" class="column is-centered has-text-centered">
                        <h4 class="title is-4">Gestionar Formulario</h4>
                    </div>
                </div>


                <form *ngIf="steps" [formGroup]="form" (ngSubmit)="submit()">
                    <mat-horizontal-stepper #stepper>
                        <mat-step *ngFor="let step of steps; let index = index; let last = last;"
                            [stepControl]="form.at(index)" errorMessage="Completa todos los campos">
                            <ng-template matStepLabel>{{ step.label }}</ng-template>
                            <formly-form [hidden]="hidenForm" [form]="form.at(index)" [model]="model" [fields]="step.fields"
                                [options]="options[index]">
                            </formly-form>

                            <div *ngIf="disabled">

                                <button *ngIf="index !== 0" matStepperPrevious class="button is-info mr-2" type="button"
                                    (click)="prevStep(index)">Volver</button>
                                <button *ngIf="last" class="button is-primary ml-1" [disabled]="!form.valid"
                                    type="submit">Enviar</button>

                            </div>







                        </mat-step>
                    </mat-horizontal-stepper>
                </form>

                <!-- <form [formGroup]="form" style="margin-top: 20px;">
                    <formly-form [model]="model" [fields]="fields" [form]="form"></formly-form>
                </form> -->


            </div>

        </div>

    </div>
</div>