import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  valueNabs: { [key: string]: boolean } = {
    "AdminEm": false,
    "Login": false,
    "AdminUs": false,
    "Hist": false,
    "Formas": false,
    "Chat": false,
    "VideoLi": false,
    "FormWeb": false,
    "Report": false,
    "GenRepo": false
  };

  dataDefault = {
    "read": {
      "allowed": true
    },
    "write": {
      "allowed": true
    },
    "additional": {
    }
  };

  constructor() {
  }


  checkPermissions() {
    if (localStorage.getItem("dynamo_user")) {
      const userData = JSON.parse(localStorage.getItem("dynamo_user"));
      //console.log("user data", userData);
      if (userData.groups[0].role.modules) {
        const data = userData.groups[0].role.modules;
        //console.log("dataaa", data);
        for (const dataModules in data) {
          const value: boolean = this.objectEmpty(data[dataModules]);
          this.passValue(value, dataModules);
        }
      }
    } else {
      return this.valueNabs = {};
    };

    return this.valueNabs;
  };

  getSpecificModule(module: string) {
    if (localStorage.getItem("dynamo_user")) {
      const userData = JSON.parse(localStorage.getItem("dynamo_user"));
      const data = userData.groups[0].role.modules;
      for (const dataModules in data) {
        if (module === dataModules) return data[dataModules];
      }
    } else {
      //console.log("entra aca");
      return this.dataDefault;
    }

  }

  objectEmpty = (data): boolean => { return Object.keys(data).length === 0 };



  passValue(value: boolean, module: string): void {
    if (value) this.valueNabs[module] = value;
  };

}
