import { Injectable } from '@angular/core';
import { NgxIzitoastService } from 'ngx-izitoast';


@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor( public iziToast: NgxIzitoastService) { 

    this.iziToast.settings({
      pauseOnHover: true,
      progressBar: true
    });
  }



  success(msj: string) {
    this.iziToast.success({
      message: msj,           
    });
  }

  closeSesion(msj: string, time : any) {
    this.iziToast.success({
      message: msj,   
      timeout: time,

    });
  }
  info(msj: string, ){
    this.iziToast.info({
      message: msj,
      
    });
  }
  error(message: string){
    this.iziToast.error({
      message: message
    });
  }

  warning(message: string){
    this.iziToast.warning({
      message: message
    });
  }

  question(): Promise<any>{
    return new Promise((resolve, reject)=>{
      this.iziToast.show({
        color: 'dark',
        icon: 'iziToast-icon fas fa-trash-alt ',
        timeout: 5000,
        message: '¿Descartar cambios de este formulario?',
        position: 'bottomRight', // bottomRight, bottomLeft, topRight, topLeft, topCenter, bottomCenter
        progressBarColor: '#14387f',
        buttons: [
          [
            '<button>Si</button>',
             (instance, toast) => {
              instance.hide({
                transitionOut: 'fadeOutUp'
              }, toast);
              resolve(true);
            }
          ],
          [
            '<button>Cancelar</button>',
            (instance, toast) => {
              instance.hide({
                transitionOut: 'fadeOutUp'
              }, toast);
              resolve(false);
            }
          ]
        ]
      });
    });
  }
  


}
