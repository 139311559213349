<div class="general-container">

    <img src="assets/home/logo1.png" class="logo">

    <button type="button" class="button is-danger" (click)="closeSesion()"><i class="fas fa-power-off mr-1"></i>
        Salir </button>


    <div *ngIf="(dataPermissionWeb['read']?.allowed) && (dataPermissionWeb['write']?.allowed)
    && (dataPermissionWeb['additional']?.moduleForms | typeof) != 'boolean'; else moduleForms" class="content1"
        (click)="changeRoute('mForm')">
        <i class="material-icons"> format_line_spacing </i>
        <h2>Formularios</h2>
    </div>
    <ng-template #moduleForms>
        <div *ngIf="!(dataPermissionWeb['read']?.allowed) || !(dataPermissionWeb['write']?.allowed)
        || (dataPermissionWeb['additional']?.moduleForms)" (click)="changeRoute('mForm')"
            class="content1 disabledButton">
            <i class="material-icons"> video_call </i>
            <h2>Formularios</h2>
        </div>
    </ng-template>



    <div *ngIf="(dataPermissionVideo['read']?.allowed) && (dataPermissionVideo['write']?.allowed)
    && (dataPermissionVideo['additional']?.enterChime | typeof) != 'boolean'; else enterChime" class="content2"
        (click)="changeRoute('vCall')">
        <i class="material-icons"> video_call </i>
        <h2>Videollamada</h2>
    </div>
    <ng-template #enterChime>
        <div *ngIf="!(dataPermissionVideo['read']?.allowed) || !(dataPermissionVideo['write']?.allowed)
        || (dataPermissionVideo['additional']?.enterChime)" class="content2" (click)="changeRoute('vCall')"
            class="content2 disabledButton">
            <i class="material-icons"> video_call </i>
            <h2>Videollamada</h2>
        </div>
    </ng-template>

    <!-- Whiteboard -->

    <div *ngIf="(dataPermissionVideo['read']?.allowed) && (dataPermissionVideo['write']?.allowed)
    && (dataPermissionVideo['additional']?.enterWhiteBoard | typeof) != 'boolean'; else enterWhiteBoard" class="content3"
        (click)="changeRoute('vWhite')">
        <i class="material-icons"> border_color </i>
        <h2>Panel Interactivo</h2>
    </div>
    <ng-template #enterWhiteBoard>
        <div *ngIf="!(dataPermissionVideo['read']?.allowed) || !(dataPermissionVideo['write']?.allowed)
        || (dataPermissionVideo['additional']?.enterWhiteBoard)" class="content2" (click)="changeRoute('vWhite')"
            class="content3 disabledButton">
            <i class="material-icons"> border_color </i>
            <h2>Panel Interactivo</h2>
        </div>
    </ng-template>


</div>