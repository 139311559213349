import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-videocall',
  templateUrl: './videocall.component.html',
  styleUrls: ['./videocall.component.css']
})
export class VideocallComponent implements OnInit {

  // @Input() showVar: boolean = false;

  constructor() {


    //console.log("ALO ALO?????")
   }

  ngOnInit() {
  }

}
