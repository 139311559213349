<div class="animated fadeInRight general-cont-users">
    <!-- <button *ngIf="!loader && !notFound && validatePage" type="button" class="btn btn-primary" style="width: auto;"><i class="fas fa-plus"></i>Crear nueva reunión</button> -->

    <progress *ngIf="!loader && !notFound && validatePage === 'tab3' && value > 1" [value]="value"
        class="progress is-small is-success" max="100"></progress>


    <button *ngIf="!loader && !notFound && validatePage === 'tab2'" class="btn btn-primary" style="width: auto;"
        (click)="openModal()"><i class="fas fa-plus-circle" style="color: white;"></i> Crear reunión</button>
    <div class="users">

        <div *ngIf="!loader && !notFound && validatePage === 'tab2'" class="users-cont">
            <div *ngFor="let user of users" class="user-objetc">
                <div class="cont-user animated zoomIn">

                    <div class="img-user">
                        <img src='assets/profile/default-user.jpg' class='imgRedonda' />

                    </div>
                    <div class="user-alias">
                        {{user.userId}}
                    </div>
                    <div class="edit-user">
                        <button class="button is-rounded btn">

                            <i class="fas fa-user-edit"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="validatePage === 'tab1' && !loader && !notFound" class="chime-cont">
            <!-- <app-list-chime-group></app-list-chime-group> -->
            <ng-container *ngIf="(dataPermission['read']?.allowed) && (dataPermission['write']?.allowed)
            && (dataPermission['additional']?.enterChime | typeof) != 'boolean'; else enterChime">
                <div *ngFor="let chime of chimeGroups; let i = index" class="chime-objetc">
                    <div class="chime-all animated zoomIn is-mobile" (click)="prueba(chime)" [queryParams]="chime"
                        [routerLink]="['/landing/videocall', chime.Id]">

                        <div>
                            <img src='assets/background.jpg' />

                        </div>
                        <div class="chime-name">
                            {{chime.name}}
                        </div>

                        <!-- <div class=" edit-user">
                                <button class="button is-rounded btn">
    
                                    <i class="fas fa-user-edit"></i>
                                </button>
                        </div> -->
                    </div>
                </div>
            </ng-container>

        </div>

        <ng-template #enterChime>
            <ng-container *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
            || (dataPermission['additional']?.enterChime)">
                <div *ngFor="let chime of chimeGroups; let i = index" class="chime-objetc">
                    <div class="chime-all animated zoomIn is-mobile disabledVideoCall" (click)="prueba(chime)" [queryParams]="chime"
                        [routerLink]="['/landing/videocall', chime.Id]">

                        <div>
                            <img src='assets/background.jpg' />

                        </div>
                        <div class="chime-name">
                            {{chime.name}}
                        </div>

                        <!-- <div class=" edit-user">
                                <button class="button is-rounded btn">
    
                                    <i class="fas fa-user-edit"></i>
                                </button>
                        </div> -->
                    </div>
                </div>
            </ng-container>
        </ng-template>


        <div *ngIf="validatePage === 'tab3' && !loader && !notFound" class="chime-cont">

            <div *ngFor="let record of recordings; let i = index" class="record-objetc">
                <div class="chime-all animated zoomIn">

                    <div>
                        <img src='assets/background.jpg' />
                    </div>
                    <div class="record-name">
                        <div>
                            {{record.serverDate}}
                        </div>

                        <div>
                            <!-- <button class="button is-rounded btnRecord" fileSaver [method]="'GET'"
                                [fileName]="'bascula.mp4'" [url]="record.url" (success)="onSuccess($event)"
                                (error)="onErr($event)">
                                Descargar
                                <br>
                                <i class="fas fa-arrow-circle-down" style="color: white;"></i>
                            </button> -->

                            <button class="button is-rounded btnRecord" fileSaver type="button"
                                (click)="onDown('mp4', true, record, $event.preventDefault())">
                                Descargar
                                <br>
                                <i class="fas fa-arrow-circle-down" style="color: white;"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>



        </div>


        <div class="floating-btn" (click)="onFloatingButtonClick()" style="visibility: hidden;">

            <span class="fa-stack fa-lg  myfloating">


                <i class="fas fa-plus fa-stack-1x fa-inverse color"></i>

            </span>

        </div>

        <div class="mymodal" *ngIf="inCreateModal">

            <div class="mytitle">Crear Conferencia</div>
            <div class="mysubtitle">Escribe un nombre para la conferencia</div>

            <div class="control has-icons-left myinput">
                <input class="input is-success" type="text" placeholder="Nombre..." [(ngModel)]="conferenceName">
                <span class="icon is-small is-left">
                    <i class="fas fa-file-video"></i>
                </span>
            </div>

            <div class="mybutton" (click)="addChimeGroup()">
                <div class="mybuttonname">Crear</div>
            </div>

        </div>

        <div *ngIf="loader" class="loader--">
            <app-loader></app-loader>
        </div>

        <div *ngIf="notFound &&  !loader" class="not-found">
            <p>Sin datos...</p>

        </div>



    </div>




</div>