import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga';
import { environment } from '../environments/environment'
import { Router, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'teammananagerWeb';
  public userConnect: any = {};
  public devices: any = [];
  public teams: any = [];
  public motives: any = {};
  public forms: any = [];



  constructor(public ngxanalyticsGoogleAnalytics: NgxAnalyticsGoogleAnalytics, public _router: Router,
    public translate: TranslateService
  ) {
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.ngxanalyticsGoogleAnalytics.pageTrack(event.urlAfterRedirects);
      }
    });
  }

  ngOnInit() {
    this.setLang();
    this.setfuckingToken();

  }

  setfuckingToken() {
    localStorage.setItem("graphToken", environment.token)
  }
  setLang() {
    if (!localStorage.getItem("language")) {
      this.translate.setDefaultLang("es");
    } else {
      this.translate.setDefaultLang(localStorage.getItem("language"));
    }


  }

  // isLoggedIn(): boolean {
  //   console.log("Entra al logged in del app.component.ts");
  //   if (localStorage.getItem('auth')) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

}
