import { Injectable } from '@angular/core';
// import { resolve } from 'q';
var AWS = require("aws-sdk");
@Injectable({
  providedIn: 'root'
})
export class AmazonService {

  constructor() { }

  bucket() {
    var bucket = new AWS.S3(
      {
        region: 'us-east-1',
        apiVersion: '2006-03-01',
        credentials: new AWS.Credentials({
          accessKeyId: 'AKIAZXTFBLJUFZGNKJL6',
          secretAccessKey: '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'
        })
      }
    );
    return bucket;
  }

  downloadBucket(folder, company, mvId?): Promise<any> {
    console.log("company downlaodbucket", folder, company, mvId);
    return new Promise((resolve, reject) => {
      var bucket = this.bucket();
      this.promise(bucket, folder, company, mvId).then((res) => {
        resolve(res);
      }, (err) => {
        reject(err);
      });
    })
  }

  private async promise(bucket, folder, company, mvId?): Promise<any> {
    console.log("folder in promise ", folder);
    let keyArrive;
    if (folder == 'profiles') {
      keyArrive = folder + "/" + company + "/" + mvId + ".jpg"
    } else {
      keyArrive = folder + "/" + company;
    }
    console.log("the folder", folder);
    console.log("key arrive", keyArrive);
    return new Promise((resolve, reject) => {
      bucket.getObject({
        Bucket: 'azlogica-ft-media',
        Key: keyArrive,
      }, (err, data) => {
        if (err) {
          resolve(null);
        } else {
          if (data) {
            const buffer = Buffer.from(data.Body.toString('base64'));
            const blob = "data:image/jpeg;base64," + buffer;
            resolve(blob)
          }
        }
      });
    })
  }

  uploadBucket(name, buffer, fKey): Promise<any> {
    return new Promise((resolve, reject) => {
      let newName: string = `https://s3.amazonaws.com/azlogica-ft-media/images/${name}.jpg`;
      // var fechaMessage = new Date();
      // fechaMessage = This.appComponent.userConnect.TZ;

      console.log("new name entra =>", newName);
      console.log("solo name", name);
      console.log("buffer?", buffer);

      this.bucket().putObject({
        Bucket: 'azlogica-ft-media',
        Key: `images/${name}.jpg`,
        Body: buffer,
        ContentType: 'image/'
      }, (err, data) => {
        if (err) {
          reject(err);
        } else {
          console.log("newName", newName);
          resolve({ 'property': newName, 'fKey': fKey });

        };

      });
    });
  }
}
