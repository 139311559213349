<div class="animated fadeInRight general-cont-users">
    <!-- <button *ngIf="!loader && !notFound && validatePage" type="button" class="btn btn-primary" style="width: auto;"><i class="fas fa-plus"></i>Crear nueva reunión</button> -->

    <div class="users">

        <div *ngIf="validatePage === 'tab1' && !loader && !notFound" class="chime-cont">
            <!-- <app-list-chime-group></app-list-chime-group> -->
            <ng-container *ngIf="(dataPermission['read']?.allowed) && (dataPermission['write']?.allowed)
            && (dataPermission['additional']?.enterWhiteBoard | typeof) != 'boolean'; else enterWhiteBoard">

                <div *ngFor="let chime of chimeGroups; let i = index" class="chime-objetc">
                    <div class="chime-all animated zoomIn" (click)="prueba(chime)">

                        <div>
                            <img src='assets/whiteboard-background.png' />

                        </div>
                        <div class="chime-name">
                            {{chime.name}}
                        </div>
                        <div *ngIf="(dataPermission['read']?.allowed) && (dataPermission['write']?.allowed)
                        && (dataPermission['additional']?.deleteWhiteBoard | typeof) != 'boolean'; else deleteWhiteBoard"
                            class="chime-name">
                            <!-- <button (click)="eliminarGroup(chime,i);$event.stopPropagation()"
                                class="button is-rounded btn">

                                <i class="fas fa-trash-alt"></i>
                            </button> -->
                            <!-- <button type="button" class="btn btn-primary btn-sm" (click)="eliminarGroup();$event.stopPropagation()">Eliminar reunión</button> -->
                        </div>
                        <ng-template #deleteWhiteBoard>
                            <ng-container *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
                            || (dataPermission['additional']?.deleteWhiteBoard)">
                                <div class="chime-name">
                                    <!-- <button (click)="eliminarGroup(chime,i);$event.stopPropagation()"
                                        class="button is-rounded btn disabledButton">

                                        <i class="fas fa-trash-alt"></i>
                                    </button> -->
                                    <!-- <button type="button" class="btn btn-primary btn-sm" (click)="eliminarGroup();$event.stopPropagation()">Eliminar reunión</button> -->
                                </div>
                            </ng-container>


                        </ng-template>

                        <!-- <div class=" edit-user">
                                <button class="button is-rounded btn">
    
                                    <i class="fas fa-user-edit"></i>
                                </button>
                        </div> -->
                    </div>
                </div>


            </ng-container>
            <ng-template #enterWhiteBoard>
                <ng-container *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
                || (dataPermission['additional']?.enterWhiteBoard)">
                    <div *ngFor="let chime of chimeGroups; let i = index" class="chime-objetc">
                        <div class="chime-all animated zoomIn disabledVideoCall" (click)="prueba(chime)"
                            [queryParams]="chime" [routerLink]="['/landing/videocall', chime.Id]">

                            <div>
                                <img src='assets/whiteboard-background.png' />

                            </div>
                            <div class="chime-name">
                                {{chime.name}}
                            </div>
                            <div *ngIf="(dataPermission['read']?.allowed) && (dataPermission['write']?.allowed)
                            && (dataPermission['additional']?.deleteWhiteBoard | typeof) != 'boolean'; else deleteWhiteBoard"
                                class="chime-name">
                                <button (click)="eliminarGroup(chime,i);$event.stopPropagation()"
                                    class="button is-rounded btn">

                                    <i class="fas fa-trash-alt"></i>
                                </button>
                                <!-- <button type="button" class="btn btn-primary btn-sm" (click)="eliminarGroup();$event.stopPropagation()">Eliminar reunión</button> -->
                            </div>
                            <ng-template #deleteWhiteBoard>
                                <ng-container *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
                                || (dataPermission['additional']?.deleteWhiteBoard)">
                                    <div class="chime-name">
                                        <button (click)="eliminarGroup(chime,i);$event.stopPropagation()"
                                            class="button is-rounded btn disabledButton">

                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                        <!-- <button type="button" class="btn btn-primary btn-sm" (click)="eliminarGroup();$event.stopPropagation()">Eliminar reunión</button> -->
                                    </div>
                                </ng-container>


                            </ng-template>

                            <!-- <div class=" edit-user">
                                    <button class="button is-rounded btn">
        
                                        <i class="fas fa-user-edit"></i>
                                    </button>
                            </div> -->
                        </div>
                    </div>
                </ng-container>

            </ng-template>


        </div>

        <!-- <div *ngIf="(dataPermission['read']?.allowed) && (dataPermission['write']?.allowed)
        && (dataPermission['additional']?.createVideoCall | typeof) != 'boolean'; else createVideoCall"
            class="floating-btn" (click)="onFloatingButtonClick()">

            <span class="fa-stack fa-lg  myfloating">


                <i class="fas fa-plus fa-stack-1x fa-inverse color"></i>

            </span>

        </div>

        <ng-template #createVideoCall>
            <ng-container *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
            || (dataPermission['additional']?.createVideoCall)">

                <div class="floating-btn-disabled" (click)="onFloatingButtonClick()">

                    <span class="fa-stack fa-lg  myfloating">


                        <i class="fas fa-plus fa-stack-1x fa-inverse color"></i>

                    </span>

                </div>
            </ng-container>

        </ng-template> -->


        <div class="mymodal" *ngIf="inCreateModal">

            <div class="mytitle">Crear Panel Interactivo</div>
            <div class="mysubtitle">Escribe un nombre para la pizarra</div>

            <div class="control has-icons-left myinput">
                <input class="input is-success" type="text" placeholder="Nombre..." [(ngModel)]="conferenceName"
                    maxlength="6">
                <span class="icon is-small is-left">
                    <i class="fas fa-file-video"></i>
                </span>
            </div>

            <div>
                <p>
                    <label>
                        <input type="checkbox" checked="checked" [disabled]="inWhiteboard" [(ngModel)]="inWhiteboard" />
                        <span>Pizarra</span>
                    </label>
                </p>

            </div>

            <div class="mybutton" (click)="addChimeGroup()">
                <div class="mybuttonname">Crear</div>
            </div>

        </div>

        <div *ngIf="loader" class="loader--">
            <app-loader></app-loader>
        </div>

        <div *ngIf="notFound &&  !loader" class="not-found">
            <p>Sin datos...</p>

        </div>



    </div>




</div>