import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { NgxIziToastModule } from 'ngx-izitoast';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';

//Http
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { NgxPowerBiModule } from 'ngx-powerbi';


/*translate*/
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';



/*AmplyFly */
import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular'

import { NgxCaptchaModule } from 'ngx-captcha';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


import Auth from '@aws-amplify/auth';
import Interactions from '@aws-amplify/interactions';

/*datepicker*/
import { OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl } from 'ng-pick-datetime';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// import { SpanishIntl } from './services/datepicker';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { InternationalPhoneModule } from 'ng4-country-phone-select';


//Grapql
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpHeaders } from '@angular/common/http';
import { ApolloLink, from } from 'apollo-link';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';



/*Components*/
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './components/home-page/home-page.component';
import { HomeComponent } from './components/home/home.component';
import { NavbarLeftComponent } from './components/navbar-left/navbar-left.component';
import { EditProfileComponent } from './components/navbar-left/edit-profile/edit-profile.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UsersHistoricPipe } from './services/pipes/users-historic.pipe';
import { ManageFormComponent } from './components/forms/manage-form/manage-form.component';



// import { NguiDatetimePickerModule } from '@ngui/datetime-picker'; // Date select
import { Ng2FilterPipeModule } from 'ng2-filter-pipe'; // Filter grid
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxAnalyticsModule } from 'ngx-analytics'; // Google Analitics
import { NgxAnalyticsGoogleAnalytics } from 'ngx-analytics/ga'; // Google analitics
import { NgxSpinnerModule } from "ngx-spinner"; // Spinner
import { ScrollDetectorDirective } from './utils/scroll-detector.directive';

import { AgmCoreModule } from '@agm/core';

//Fullscreen
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';

//Filesaver
import { FileSaverModule } from 'ngx-filesaver';

//formly (forms)
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { FileValueAccessor } from '../app/components/forms/manage-form/file-value-accessor';
import { FormlyFieldFile } from '../app/components/forms/manage-form/file-type.component';

//--
import { environment } from "../environments/environment";
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TransformDataPipe } from './pipes/transform-data.pipe';
import { HomeManageFormComponent } from './components/home-manage-form/home-manage-form.component';
import { VideocallComponent } from './components/videocall/videocall.component';
import { FootbarComponent } from './components/videocall/footbar/footbar.component';
import { LandingComponent } from './components/videocall/landing/landing.component';
import { ListUserSessionsComponent } from './components/videocall/list-user-sessions/list-user-sessions.component';
import { ModalCreateChimeGroupComponent } from './components/videocall/modal-create-chime-group/modal-create-chime-group.component';
import { UsersConferenceComponent } from './components/videocall/users-conference/users-conference.component';
import { UsersSessionsComponent } from './components/videocall/users-sessions/users-sessions.component';
import { VideoSideBarComponent } from './components/videocall/video-side-bar/video-side-bar.component';
import { VideocallNavbarComponent } from './components/videocall/videocall-navbar/videocall-navbar.component';
import { TypeofPipe } from './services/pipes/typeof.pipe';
import { WhiteboardComponent } from './components/whiteboard/whiteboard.component';
import { WhiteboardAllComponent } from './components/whiteboard/whiteboard-all/whiteboard-all.component';
import { WhiteboardSessionsComponent } from './components/whiteboard/whiteboard-sessions/whiteboard-sessions.component';










const language = navigator.language;

const uri = environment.urlGraph;
const urlSocket = environment.urlSocket;


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomePageComponent,
    HomeComponent,
    NavbarLeftComponent,
    EditProfileComponent,
    LoaderComponent,
    UsersHistoricPipe,
    ScrollDetectorDirective,
    TransformDataPipe,
    ManageFormComponent,
    FileValueAccessor,
    FormlyFieldFile,
    HomeManageFormComponent,
    VideocallComponent,
    FootbarComponent,
    LandingComponent,
    ListUserSessionsComponent,
    ModalCreateChimeGroupComponent,
    UsersConferenceComponent,
    UsersSessionsComponent,
    VideoSideBarComponent,
    VideocallNavbarComponent,
    TypeofPipe,
    WhiteboardComponent,
    WhiteboardAllComponent,
    WhiteboardSessionsComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    EmojiModule,
    DragDropModule,
    NgxPowerBiModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    InfiniteScrollModule,
    PickerModule,
    OwlNativeDateTimeModule,
    AppRoutingModule,
    NgxIziToastModule,
    HttpClientModule,
    HttpModule,
    ApolloModule,
    HttpLinkModule,
    Ng2FilterPipeModule,
    NgxPaginationModule,
    MatStepperModule,
    // NguiDatetimePickerModule,
    InternationalPhoneModule,
    NgxPageScrollModule,
    FileSaverModule,
    NgxAnalyticsModule.forRoot([NgxAnalyticsGoogleAnalytics], {
      pageTracking: {
        clearIds: true,
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
        },
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBEgAprMAWiIrIaqEg_U4BHGRpQLm8p1JI'
    }),
    NgxCaptchaModule,
    NguiAutoCompleteModule,
    NgxIntlTelInputModule,
    AmplifyAngularModule,
    NgImageFullscreenViewModule,
    NgbModule,
    NgxSpinnerModule,
    FormlyModule.forRoot({
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
      types: [
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] }
      ]
    }),
    FormlyBootstrapModule
  ],
  providers: [
    AppComponent,
    NgbActiveModal,
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        });
      }
    },
    { provide: OWL_DATE_TIME_LOCALE, useValue: language }
    // { provide: OwlDateTimeIntl, useClass: SpanishIntl }


  ],
  bootstrap: [AppComponent],
  entryComponents: [],
  exports: []
})
export class AppModule {
  constructor(
    apollo: Apollo,
    httpLink: HttpLink,
  ) {

    const http = httpLink.create({
      uri: uri
    });

    // Create a WebSocket link:
    const ws = new WebSocketLink({
      uri: urlSocket,
      options: {
        reconnect: true
      }
    });

    interface Definintion {
      kind: string;
      operation?: string;
    };

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation }: Definintion = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      ws,
      http,
    );

    const authMiddleware = new ApolloLink((operation, forward) => {
      // add the authorization to the headers
      // we assume `headers` as a defined instance of HttpHeader
      let token = localStorage.getItem('auth');
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', token ? `Bearer ${token}` : '')
      });
      return forward(operation);
    });

    const middlewareAuthLink = setContext(async (req, { headers }) => {
      setTimeout(() => {
        let token = localStorage.getItem('auth');
        return {
          headers: new HttpHeaders().set('Authorization', token ? `Bearer ${token}` : null)
        }

      }, 1000)

    })

    apollo.create({
      link: from([authMiddleware, middlewareAuthLink, link]),
      cache: new InMemoryCache()
    });


  }
}
