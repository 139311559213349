import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment'
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  getFormsData, getSessions, getChimeGroup, getFormData,
  getChimeGroups, createChimeGroup, getUser, deleteChimeGroup,
  getUsers, getReports, getReportsUser, getForms, getReportsUsersLast,
  getCheckUser, getVideo, getFormId, createFormData, insertVideo, getFormForSpecificId
} from "../graphql/form"
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import * as Observable from "zen-observable";
import { resolve } from 'url';
import { Apollo } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ChimeService {

  constructor(public http: HttpClient,
    public apollo: Apollo) { }

  zeus = environment.zeus;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  async getFormsData(params): Promise<any> {
    //console.log("params getFormsData", params)
    const response = (await API.graphql(
      graphqlOperation(getFormsData, params)
    )) as any;
    //console.log("responde params getFormsData", response)
    return <any>response.data.getFormsData;
  };


  async getSessions(params): Promise<any> {
    //console.log("params getSessions", params)
    const response = (await API.graphql(
      graphqlOperation(getSessions, params)
    )) as any;
    //console.log("responde params getSessions", response)
    return <any>response.data.getSessions;
  };

  async getChimeGroup(params): Promise<any> {
    //console.log("params getChimeGroup", params)
    const response = (await API.graphql(
      graphqlOperation(getChimeGroup, params)
    )) as any;
    //console.log("responde params getChimeGroup", response)
    return <any>response.data.getChimeGroup;
  };

  async deleteChimeGroup(params): Promise<any> {
    //console.log("params deleteChimeGroup", params)
    const response = (await API.graphql(
      graphqlOperation(deleteChimeGroup, params)
    )) as any;
    //console.log("responde params deleteChimeGroup", response)
    return <any>response.data.deleteChimeGroup;
  };


  async getChimeGroups(params): Promise<any> {
    //console.log("params getChimeGroups", params)
    const response = (await API.graphql(
      graphqlOperation(getChimeGroups, params)
    )) as any;
    //console.log("responde params getChimeGroups", response)
    return <any>response.data.getChimeGroups;
  };


  async createChimeGroup(params): Promise<any> {
    //console.log("params createChimeGroup", params)
    const response = (await API.graphql(
      graphqlOperation(createChimeGroup, params)
    )) as any;
    //console.log("responde params getChimeGroups", response)
    return <any>response.data.createChimeGroup;
  };



  async getFormData(params): Promise<any> {
    //console.log("params getFormData", params)
    const response = (await API.graphql(
      graphqlOperation(getFormData, params)
    )) as any;
    //console.log("responde params getFormData", response)
    return <any>response.data.getFormData;
  };

  async getForms(params): Promise<any> {
    //console.log("params getForms", params)
    const response = (await API.graphql(
      graphqlOperation(getForms, params)
    )) as any;
    //console.log("responde params getForms", response)
    return <any>response.data.getForms;
  };

  async getUser(params): Promise<any> {
    //console.log("params getUser", params)
    const response = (await API.graphql(
      graphqlOperation(getUser, params)
    )) as any;
    //console.log("responde params getUser", response)
    return <any>response.data.getUser;
  };

  async getUsers(params): Promise<any> {
    //console.log("params getUsers", params)
    const response = (await API.graphql(
      graphqlOperation(getUsers, params)
    )) as any;
    //console.log("responde params getUsers", response)
    return <any>response.data.getUsers;
  };


  async getReports(params): Promise<any> {
    //console.log("params getReports", params)
    const response = (await API.graphql(
      graphqlOperation(getReports, params)
    )) as any;
    //console.log("responde params getReports", response)
    return <any>response.data.getReports;
  };

  async getReportsUser(params): Promise<any> {
    //console.log("params getReportsUser", params)
    const response = (await API.graphql(
      graphqlOperation(getReportsUser, params)
    )) as any;
    //console.log("responde params getReportsUser", response)
    return <any>response.data.getReportsUser;
  };

  async getReportsUsersLast(params): Promise<any> {
    const response = (await API.graphql(
      graphqlOperation(getReportsUsersLast, params)
    )) as any;
    return <any>response.data.getReportsUserLast;
  };

  async getCheckUser(params): Promise<any> {
    //console.log("params getCheckUser", params)
    const response = (await API.graphql(
      graphqlOperation(getCheckUser, params)
    )) as any;
    //console.log("responde params getCheckUser", response)
    return <any>response.data.checkUser;
  };

  async getVideo(params): Promise<any> {
    const response = (await API.graphql(
      graphqlOperation(getVideo, params)
    )) as any;
    return <any>response.data.getVideo;
  };

  async insertVideo(params): Promise<any> {
    //console.log("params insertVideo", params)
    const response = (await API.graphql(
      graphqlOperation(insertVideo, params)
    )) as any;
    //console.log("responde insertVideo", response)
    return <any>response.data.insertVideo;
  };


  async getFormId(params): Promise<any> {
    //console.log("params getFormId", params)
    const response = (await API.graphql(
      graphqlOperation(getFormId, params)
    )) as any;
    //console.log("responde params getFormId", response)
    return <any>response.data.getFormId;
  };

  async getFormForSpecificId(params): Promise<any> {
    //console.log("params getFormForSpecificId", params)
    const response = (await API.graphql(
      graphqlOperation(getFormForSpecificId, params)
    )) as any;
    //console.log("responde params getFormForSpecificId", response)
    return <any>response.data.getForms;
  };

  async CreateFormData(params): Promise<any> {
    //console.log("params CreateFormData", params)
    const response = (await API.graphql(
      graphqlOperation(createFormData, params)
    )) as any;
    //console.log("responde params createFormData", response)
    return <any>response.data.createFormData;
  };

  download(url) {
    //console.log("url que llega", url);
    return this.http.get(url,
      { responseType: 'blob', reportProgress: true, observe: 'events' })
  }

}
