<div class="container-login-general">
  <div style="background-image: url('assets/home/LOGIN.jpg');" class="image-left">

  </div>
  <div class=" login-form">



    <div *ngIf="!recovery && !phoneLogin && !validate_recovery " class="taget-login animated zoomIn">
      <div class="card target-card box" style="color:#39f">
        <header class="card-header ">

          <div class="img-login">
            <img src="assets/home/LOGO.png" style="height: auto;max-width: 60%;">

          </div>
        </header>

        <div class="card-content">

          <form [formGroup]="loginForm">

            <div class="field">

              <div class="control has-icons-left">
                <input formControlName="user" class="input is-success" type="text"
                  placeholder="{{ 'login.user_email' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
              </div>
            </div>


            <ng-container *ngFor="let error of error_messages.user">
              <div class="error-message"
                *ngIf="loginForm.get('user').hasError(error.type) && (loginForm.get('user').dirty || loginForm.get('user').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>




            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="password" class="input is-success" [type]="showPass ? 'text' : 'password'"
                  placeholder="{{ 'login.password' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
                <span *ngIf="showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye"></i>
                </span>
                <span *ngIf="!showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye-slash"></i>
                </span>

              </div>

            </div>


            <ng-container *ngFor="let error of error_messages.password">
              <div class="error-message"
                *ngIf="loginForm.get('password').hasError(error.type) && (loginForm.get('password').dirty || loginForm.get('password').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>



          </form>


        </div>
        <div class="cont-card-footer">
          <div>
            <button [ngClass]="{ 'animated rubberBand is-loading' : loading} " (click)="loginMethod()"
              class="button btn">{{
              'login.login' | translate }}</button>
          </div>
          <!-- <ng-template #buttonLogin>
            <button [ngClass]="{ 'animated rubberBand is-loading' : loading} " (click)="loginMethod()"
              class="button btn disabled">{{
              'login.login' | translate }}</button>
          </ng-template> -->

          <div style="color: white;padding-top: 2%;"><a class="disabledLink" (click)="phoneLogin =!phoneLogin"
              style="color: white;padding-top: 2%;">{{
              'login.phone' | translate }}</a>
          </div>
          <!-- <ng-template #enterWithCellNumber>
            <div
              *ngIf="(dataPermission['read']?.allowed) && (dataPermission['additional']?.enterWithCellNumber | typeof) != 'boolean'"
              style="color: white;padding-top: 2%;"><a (click)="phoneLogin =!phoneLogin"
                style="color: white;padding-top: 2%;">{{
                'login.phone' | translate }}</a>
            </div>
          </ng-template> -->

          <div

            style="color: white;padding-top: 2%;"><a (click)="recoveryModale()" style="color: white;padding-top: 2%;">{{
              'login.forgot' | translate }}</a>
          </div>
          <!-- <ng-template #forgotPasswordLogin>
            <div *ngIf="(dataPermission['additional']?.forgotPasswordLogin) || !(dataPermission['read']?.allowed)"
              style="color: white;padding-top: 2%;"><a class="disabledLink" (click)="recoveryModale()"
                style="color: white;padding-top: 2%;">{{ 'login.forgot' | translate }}</a>
            </div>
          </ng-template> -->
        </div>
        <div>
          <div class="apps">
            <div class="gplay">
              <a
                href='http://play.google.com/store/apps/details?id=com.azlogica.fuerzatrabajo&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img src="assets/home/{{language}}/googleplay.png" class="gplay">
              </a>
            </div>
            <div class="appstore">
              <a href='https://itunes.apple.com/us/app/team-manager/id1140294865?l=es&ls=1&mt=8'>
                <img src="assets/home/{{language}}/appstore.png" class="appstore">
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>


    <div *ngIf="!recovery && phoneLogin && !validate_recovery " class="taget-login animated zoomIn">
      <div class="card target-card box" style="color:#39f">
        <header class="card-header ">

          <div class="img-login">
            <img src="assets/home/LOGO.png" style="height: auto;max-width: 60%;">

          </div>
        </header>

        <div class="card-content">

          <form [formGroup]="phoneForm">

            <div class="field">

              <!-- <div class="select">
                <select>
                  <option>Select dropdown</option>
                  <option>With options</option>
                </select>
              </div> -->
              <div class="control has-icons-left">
                <input formControlName="phone" class="input is-success" type="text"
                  placeholder="{{ 'login.user_phone' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-phone-square-alt"></i>
                </span>
              </div>
            </div>


            <ng-container *ngFor="let error of error_messages.phone">
              <div class="error-message"
                *ngIf="phoneForm.get('phone').hasError(error.type) && (phoneForm.get('phone').dirty || phoneForm.get('phone').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>




            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="password" class="input is-success" [type]="showPass ? 'text' : 'password'"
                  placeholder="{{ 'login.password' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
                <span *ngIf="showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye"></i>
                </span>
                <span *ngIf="!showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
            <ng-container *ngFor="let error of error_messages.password">
              <div class="error-message"
                *ngIf="phoneForm.get('password').hasError(error.type) && (phoneForm.get('password').dirty || phoneForm.get('password').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>



          </form>


        </div>

        <div class="cont-card-footer">
          <div>
            <button

              [ngClass]="{ 'animated rubberBand is-loading' : loading} " (click)="loginMethodPhone()"
              class="button btn">{{ 'login.login' | translate }}</button>
          </div>

          <!-- <ng-template #buttonEnterWithCellNumber>
            <button *ngIf="(dataPermission['additional']?.buttonEnterWithCellNumber ) || !(dataPermission['read']?.allowed)
              || !(dataPermission['write']?.allowed )" [ngClass]="{ 'animated rubberBand is-loading' : loading} "
              (click)="loginMethodPhone()" class="button btn disabledButton">{{ 'login.login' | translate }}</button>
          </ng-template> -->



          <div

            style="color: white;padding-top: 2%;"><a class="disabledLink" (click)="phoneLogin =!phoneLogin"
              style="color: white;padding-top: 2%;">{{ 'login.email' | translate }}</a>

          </div>
          <!-- <ng-template #enterWithEmail>
            <ng-container
              *ngIf="(dataPermission['read']?.allowed) && (dataPermission['additional']?.enterWithEmail | typeof) != 'boolean'">
              <div style="color: white;padding-top: 2%;"><a (click)="phoneLogin =!phoneLogin"
                  style="color: white;padding-top: 2%;">{{ 'login.email' | translate
                  }}</a>
              </div>
            </ng-container>
          </ng-template> -->


          <div style="color: white;padding-top: 2%;"><a (click)="recoveryModale()"
              style="color: white;padding-top: 2%;">{{ 'login.forgot' | translate }}</a>

          </div>
        </div>
        <div>
          <div class="apps">
            <div class="gplay">
              <a
                href='http://play.google.com/store/apps/details?id=com.azlogica.fuerzatrabajo&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img src="assets/home/{{language}}/googleplay.png" class="gplay">
              </a>
            </div>
            <div class="appstore">
              <a href='https://itunes.apple.com/us/app/team-manager/id1140294865?l=es&ls=1&mt=8'>
                <img src="assets/home/{{language}}/appstore.png" class="appstore">
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>



    <div *ngIf="recovery" class="taget-login animated zoomIn">
      <div class="card target-card box" style="color:#39f">
        <header class="card-header ">

          <div class="img-login">
            <img src="assets/home/LOGO.png" style="height: auto;max-width: 60%;">
          </div>
        </header>
        <div class="recovery">
          <div class="return-login">
            <a (click)="recoveryModale()">
              <i style="color:white" class="fas fa-angle-left"></i>
            </a>
          </div>
          <div class="recovery-tittle">
            <label> {{ 'login.recovery' | translate }}</label>
          </div>

        </div>

        <div class="card-content">


          <form [formGroup]="forgotPassword">

            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="user_name" class="input is-success" type="text"
                  placeholder="{{ 'login.user' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-user"></i>
                </span>
              </div>
            </div>

          </form>

        </div>
        <div class="cont-card-footer">
          <div>
            <button 
              (click)="recoveryPassword()" class="button btn">{{ 'login.recovery_send' | translate }}</button>
          </div>
          <!-- <ng-template #buttonForgotPassword>
            <button *ngIf="!(dataPermission['read']?.allowed) || !(dataPermission['write']?.allowed)
            || (dataPermission['additional']?.buttonForgotPassword)" (click)="recoveryPassword()"
              class="button btn disabled">{{ 'login.recovery_send' | translate
              }}</button>
          </ng-template> -->
          <div>
            <div class="apps">
              <div class="gplay">
                <a
                  href='http://play.google.com/store/apps/details?id=com.azlogica.fuerzatrabajo&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img src="assets/home/{{language}}/googleplay.png" class="gplay">
                </a>
              </div>
              <div class="appstore">
                <a href='https://itunes.apple.com/us/app/team-manager/id1140294865?l=es&ls=1&mt=8'>
                  <img src="assets/home/{{language}}/appstore.png" class="appstore">
                </a>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>



    <div *ngIf="validate_recovery" class="taget-login animated zoomIn">
      <div class="card target-card box" style="color:#39f">
        <header class="card-header ">

          <div class="img-login">
            <img src="assets/home/LOGO.png" style="height: auto;max-width: 60%;">
          </div>
        </header>
        <div class="recovery">
          <!-- <div class="return-login">
            <a (click)="recoveryModale()"> 
              <i style="color:white"class="fas fa-angle-left"></i>
            </a>
          </div> -->
          <div class="recovery-tittle">
            <label> {{ 'login.recovery' | translate }}</label>
          </div>

        </div>

        <div class="card-content">


          <form [formGroup]="newPassword">

            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="code" class="input is-success" type="text"
                  placeholder="{{ 'login.code' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-shield-alt"></i>
                </span>

              </div>
            </div>

            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="password" class="input is-success" [type]="showPass ? 'text' : 'password'"
                  placeholder="{{ 'login.password' | translate }}">
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
                <span *ngIf="showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye"></i>
                </span>
                <span *ngIf="!showPass" class="icon is-small is-right" style="right: 0; cursor: pointer; z-index: 10;"
                  (click)="showPassword()">
                  <i class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
            <ng-container *ngFor="let error of error_messages.password">
              <div class="error-message"
                *ngIf="newPassword.get('password').hasError(error.type) && (newPassword.get('password').dirty || newPassword.get('password').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>


            <div class="field">
              <div class="control has-icons-left">
                <input formControlName="confirm_password" class="input is-success"
                  [type]="showPassRecovery ? 'text' : 'password'" placeholder="{{ 'login.new_password' | translate }}"
                  (keyup)="validatePassword($event.target.value)">
                <span class="icon is-small is-left">
                  <i class="fas fa-key"></i>
                </span>
                <span *ngIf="showPassRecovery" class="icon is-small is-right"
                  style="right: 0; cursor: pointer; z-index: 10;" (click)="showPassReco()">
                  <i class="fas fa-eye"></i>
                </span>
                <span id="spanShowPass" *ngIf="!showPassRecovery" class="icon is-small is-right"
                  style="right: 0; cursor: pointer; z-index: 10;" (click)="showPassReco()">
                  <i class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
            <ng-container *ngFor="let error of error_messages.confirm_password">
              <div class="error-message"
                *ngIf="newPassword.get('confirm_password').hasError(error.type) && (newPassword.get('confirm_password').dirty || newPassword.get('confirm_password').touched)">
                <i class="fas fa-exclamation-circle"></i> {{ error.message }}
              </div>
            </ng-container>

            <div *ngIf="newPassword.get('password').valid ==  true" class="errosConf">
              <div *ngIf="password" class="password-true">
                <i class="far fa-check-circle"></i>{{ errorPassword }}
              </div>
              <div *ngIf="!password" class="error-message">
                <span>
                  <i class="fas fa-exclamation-circle"></i>
                  {{ errorPassword }}
                </span>
              </div>
            </div>

          </form>

        </div>
        <div class="cont-card-footer">
          <div>
            <button (click)="changuePass()" class="button btn">{{ 'login.changue' | translate }}</button>
          </div>

          <div>
            <div class="apps">
              <div class="gplay">
                <a
                  href='http://play.google.com/store/apps/details?id=com.azlogica.fuerzatrabajo&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img src="assets/home/{{language}}/googleplay.png" class="gplay">
                </a>
              </div>
              <div class="appstore">
                <a href='https://itunes.apple.com/us/app/team-manager/id1140294865?l=es&ls=1&mt=8'>
                  <img src="assets/home/{{language}}/appstore.png" class="appstore">
                </a>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>


  </div>