<!-- Initial meeting authentication screen with meeting and name inputs -->

<div id="flow-authenticate" class="flow text-center p-2" style=" display: none !important;">
  <div class="text-muted" style="position:fixed;right:3px;bottom:3px;" id="sdk-version"></div>
  <div class="container">
    <form id="form-authenticate">
      <h1 class="h3 mb-3 font-weight-normal">Join a meeting</h1>
      <div class="row mt-3">
        <label for="inputMeeting" class="sr-only">Meeting Title</label>
        <input type="name" id="inputMeeting" class="form-control" placeholder="Meeting Title" required autofocus />
      </div>
      <div class="row mt-3">
        <label for="inputName" class="sr-only">Your Name</label>
        <input type="name" id="inputName" class="form-control" placeholder="Your Name" required />
      </div>
      <div class="row mt-3">
        <label for="inputRegion" class="sr-only">Media Region</label>
        <select id="inputRegion" class="custom-select" style="width:100%">
          <option value="us-east-1" selected>United States (N. Virginia)</option>
          <option value="ap-northeast-1">Japan (Tokyo)</option>
          <option value="ap-southeast-1">Singapore</option>
          <option value="af-south-1">South Africa (Cape Town)</option>
          <option value="eu-south-1">Italy (Milan)</option>
          <option value="ap-south-1">India (Mumbai)</option>
          <option value="ap-northeast-2">South Korea (Seoul)</option>
          <option value="ap-southeast-2">Australia (Sydney)</option>
          <option value="ca-central-1">Canada</option>
          <option value="eu-central-1">Germany (Frankfurt)</option>
          <option value="eu-north-1">Sweden (Stockholm)</option>
          <option value="eu-west-1">Ireland</option>
          <option value="eu-west-2">United Kingdom (London)</option>
          <option value="eu-west-3">France (Paris)</option>
          <option value="sa-east-1">Brazil (São Paulo)</option>
          <option value="us-east-2">United States (Ohio)</option>
          <option value="us-west-1">United States (N. California)</option>
          <option value="us-west-2">United States (Oregon)</option>
        </select>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <label for="optional-features">Optional Features</label>
          <select id="optional-features" class="custom-select" style="width:100%">
            <option value="" selected>None</option>
            <option value="simulcast">Enable Simulcast For Chrome</option>
            <option value="webaudio">Use WebAudio</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <button id="authenticate" class="btn btn-lg btn-primary btn-block" type="submit">Continue</button>
      </div>
      <div class="row mt-3">
        <p>Anyone with access to the meeting link can join.</p>
      </div>
      <a id="to-sip-flow" class="row mt-3" href="#">Joining via SIP? Click here.</a>
      <div class="row mt-3">
        <div id="progress-authenticate" class="w-100 progress progress-hidden">
          <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Authenticate for SIP with meeting and voice connector ID -->

<div id="flow-sip-authenticate" class="flow text-center" style=" display: none !important;">
  <div class="container">
    <form id="form-sip-authenticate">
      <h1 class="h3 mb-3 font-weight-normal">Join a meeting via SIP</h1>
      <div class="row mt-3">
        <label for="sip-inputMeeting" class="sr-only">Meeting Title</label>
        <input type="name" id="sip-inputMeeting" class="form-control" placeholder="Meeting Title" required autofocus />
      </div>
      <div class="row mt-3">
        <label for="voiceConnectorId" class="sr-only">Voice Connector ID</label>
        <input type="name" id="voiceConnectorId" class="form-control" placeholder="Voice Connector ID" required />
      </div>
      <div class="row mt-3">
        <button id="button-sip-authenticate" class="btn btn-lg btn-primary btn-block" type="submit">Continue</button>
      </div>
      <div class="row mt-3">
        <p>You will need a SIP client in order to join the meeting.</p>
      </div>
    </form>
  </div>
</div>






<div id="flow-failed-meeting" class="flow" style=" display: none !important;">
  <div class="container">
    <form id="form-failed-meeting">
      <div class="card border-warning mb-3" style="max-width: 20rem;">
        <div id="failed-meeting" class="card-header"></div>
        <div class="card-body">
          <h4 class="card-title">No es posible encontrar esta conferencia</h4>
          <p class="card-text">Hay un conflicto entrando a la sala, vuelve a intentar de nuevo,</p>
          <small id="failed-meeting-error" class="text-muted"></small>
        </div>
      </div>
      <button class="btn btn-lg btn-outline-warning btn-block" type="submit">OK</button>
    </form>
  </div>
</div>

<!-- Prompt for permission to get access to device labels -->

<div id="flow-need-permission" class="flow" style=" display: none ;">
  <div class="container">
    <form id="form-need-permission">
      <div class="card border-info mb-3" style="max-width: 20rem;">
        <div class="card-header">Chequeo de permisos</div>
        <div class="card-body">
          <h4 class="card-title">No es posible enncontrar dispositivos</h4>
          <p class="card-text">Necesitamos que aceptes los permisos de usos de camara y audio en tu navegador.</p>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Display SIP URI -->

<div id="flow-sip-uri" class="flow" style=" display: none ;">
  <div class="container">
    <form id="sip-uri-form">
      <div class="card border-info mb-3" style="max-width: 20rem;">
        <div class="card-header">SIP URI</div>
        <div class="card-body">
          <a id="copy-sip-uri" class="card-title" href="#">Copy</a>
          <input id="sip-uri" class="card-text" />
        </div>
      </div>
    </form>
  </div>
</div>

<!-- Device management and preview screen -->

<div id="flow-devices" class="flow" style=" display: none ;">
  <div class="container">
    <form id="form-devices">
      <h1 class="h3 mb-3 font-weight-normal text-center">Opciones</h1>
      <div class="row mt-3">
        <div class="col-12 col-sm-8">
          <label for="audio-input block">Microfono</label>
          <select id="audio-input" class="custom-select" style="width:100%"></select>
        </div>
        <div class="text-center col-sm-4 d-sm-block">
          <label>Vista previa</label>
          <div class="w-100 progress" style="margin-top:0.75rem">
            <div id="audio-preview" class="progress-bar bg-success" role="progressbar" aria-valuenow="0"
              aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-8">
          <label for="video-input block">Cámara</label>
          <select id="video-input" class="custom-select" style="width:100%"></select>
        </div>
        <div class="col-sm-4 text-center d-sm-block video-preview">
          <video id="video-preview" class="w-100 h-100"
            style="max-width:137px;max-height:82px;border-radius:8px"></video>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-8">
          <select id="video-input-quality" class="custom-select" style="width:100%">
            <!-- <option value="360p">360p (nHD) @ 15 fps (600 Kbps max)</option>
            <option value="540p">540p (qHD) @ 15 fps (1.4 Mbps max)</option> -->
            <option value="720p" selected>720p (HD) @ 15 fps (1.4 Mbps max)</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-8">
          <label for="audio-output block">Sonido</label>
          <select id="audio-output" class="custom-select" style="width:100%"></select>
        </div>
        <div class="col-sm-4">
          <button id="button-test-sound" class="btn btn-outline-secondary btn-block h-50 d-sm-block"
            style="margin-top:2rem">Probar</button>
        </div>
      </div>



      <div class="row mt-3">
        <div class="col-lg">
          <button id="joinButton" class="btn btn-lg btn-primary btn-block" type="submit">Unirse</button>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg">
          <!--   <p>Ready to join meeting <b><span id="info-meeting"></span></b> as <b><span id="info-name"></span></b>.</p> -->
        </div>
      </div>
    </form>
    <div id="progress-join" class="w-100 progress progress-hidden">
      <div class="w-100 progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100"
        aria-valuemin="0" aria-valuemax="100"></div>
    </div>
  </div>
</div>

<!-- In-meeting screen -->

<div id="flow-meeting" class="flow2" style="left:0;top:0;bottom:55px;right:0; display: block !important;"
  [ngStyle]="{'display': inMeeting == true ? 'block !important' : 'none' }">
  <!--
  <div class="conversation">

    <div class="conversation-general">

      <app-videocall-navbar></app-videocall-navbar>
      <div class="chat">
        <app-video-side-bar></app-video-side-bar>
        <button type="button" class="btn btn-light btn-sm buttonRecord"><i class="fas fa-record-vinyl"
            style="color: red; margin-right: 5px;"></i><strong>LIVE</strong></button>

      </div>

      <app-footbar></app-footbar>


    </div>
  </div>

-->

  <div class="p-2 d-none d-sm-block align-items-end" style="position:fixed;right:0;bottom:0;left:0;">


    <div class="row align-items-end">
      <div class="col">
        <div id="chime-meeting-id" class="text-muted"></div>
        <div id="desktop-attendee-id" class="text-muted"></div>
      </div>
      <div class="col d-none d-lg-block">
        <div id="video-uplink-bandwidth" class="text-muted text-right"></div>
        <div id="video-downlink-bandwidth" class="text-muted text-right"></div>
      </div>
    </div>
  </div>
  <audio id="meeting-audio" style="display:none"></audio>
  <div id="meeting-container" class="container-fluid h-100" style="display:flex; flex-flow:column">
    <div class="row mb-3 mb-lg-0" style="margin-left: 0 !important; margin-right: 0 !important;">
      <div class="col-12 col-lg-3 order-1 order-lg-1 text-center text-lg-left">
        <div id="meeting-id" class="navbar-brand text-muted m-0 m-lg-2"></div>
        <div id="mobile-chime-meeting-id" class="text-muted d-block d-sm-none" style="font-size:0.65rem;"></div>
        <div id="mobile-attendee-id" class="text-muted d-block d-sm-none mb-2" style="font-size:0.65rem;"></div>
      </div>
      <div class="col-8 col-lg-6 order-2 order-lg-2 text-left text-lg-center">
        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle microphone">
          <button id="button-microphone" type="button" class=" btn-success" title="Toggle microphone"
            (click)="dataAudioShow = !dataAudioShow">

            <span *ngIf="!dataAudioShow" class="fa-stack fa-lg image" style="color: rgb(255, 255, 255);">

              <i class="fas fa-microphone-slash fa-stack-1x fa-inverse color" style="color: red;"></i>


            </span>

            <span *ngIf="dataAudioShow" class="fa-stack fa-lg image" style="color: rgb(255, 255, 255);">

              <i class="fas fa-microphone fa-stack-1x fa-inverse color" style="color: black;"></i>


            </span>

            <!--        ${require('../../node_modules/open-iconic/svg/microphone.svg').default} -->
          </button>

          <!--
          <div class="btn-group" role="group">
            <button id="button-microphone-drop" type="button" class="btn btn-success dropdown-toggle"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select microphone"></button>
            <div id="dropdown-menu-microphone" class="dropdown-menu dropdown-menu-right"
              aria-labelledby="button-microphone-drop" x-placement="bottom-start"
              style="position: absolute; transform: translate3d(0px, 38px, 0px); top: 0px; left: 0px; will-change: transform;">
              <a class="dropdown-item" href="#">Default microphone</a>
            </div>
          </div>

        -->
        </div>

        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Toggle camera">
          <button id="button-camera" type="button" class=" btn-outline-secondary" title="Toggle camera"
            (click)="dataVideoShow = !dataVideoShow">

            <span *ngIf="!dataVideoShow" class="fa-stack fa-lg image">


              <!-- <i class="fas fa-video fa-stack-1x fa-inverse color" style="color: red !important;"></i> -->
              <i class="fas fa-video-slash fa-stack-1x fa-inverse color" style="color: black;"></i>
            </span>

            <span *ngIf="dataVideoShow" class="fa-stack fa-lg image">


              <i class="fas fa-video fa-stack-1x fa-inverse color" style="color: black"></i>
            </span>

          </button>

        </div>


        <div class="btn-group mx-1 mx-xl-2 my-2" role="group" aria-label="Switch camera">
          <button id="dropdown-menu-camera" type="button" class=" btn-outline-secondary" title="Switch camera"
            (click)="testVideoChange()">

            <span class="fa-stack fa-lg image">


              <i class="fas fa-sync fa-stack-1x fa-inverse color"
                [ngStyle]="{ 'color' : buttonStates['button-microphone'] == true ? 'black' : 'white' }"></i>
        

            </span>

          </button>

        </div>


      </div>
      <div class="col-4 col-lg-3 order-3 order-lg-3 text-right text-lg-right">


        <button [routerLink]="['/landing/videocall']" id="button-meeting-leave" type="button"
          class=" btn-outline-success mx-1 mx-xl-2 my-2 px-4" title="Leave meeting">

          <span class="fa-stack fa-lg image" style="color: rgb(255, 255, 255); display: flex;">
            <i class="fas fa-circle fa-stack-2x color"></i>
            <i class="fas fa-sign-out-alt fa-rotate-180 fa-stack-1x fa-inverse color" style="color: black;"></i>

            <!-- <i class="fas fa-play-circle fa-stack-1x fa-inverse color" style="color: black;"></i> -->
          </span>

          <!-- <span class="fa-stack fa-lg image" style="color: rgb(255, 255, 255);">
            <img src="/assets/regreso usuario 2.svg" alt="">
            <i class="fas fa-users fa-stack-1x fa-inverse color" style="color: black;"></i>
          </span> -->
          <!--     ${require('../../node_modules/open-iconic/svg/account-logout.svg').default} -->
        </button>


        <!-- <button id="button-meeting-end" type="button" class=" btn-outline-danger mx-1 mx-xl-2 my-2 px-4"
          title="End meeting">


          <span class="fa-stack fa-lg image" style="color: rgb(255, 255, 255);">
            <i class="fas fa-circle fa-stack-2x color"></i>
            <i class="fas fa-power-off fa-stack-1x fa-inverse color" style="color: black;"></i>

          </span>
             ${require('../../node_modules/open-iconic/svg/power-standby.svg').default}
        </button> -->

      </div>
    </div>
    <div id="roster-tile-container" class="flex-sm-grow-1 overflow-hidden h-100"
      style="max-height: 87vh !important; overflow: scroll !important;">
      <!--
      <div id="roster-message-container" class="d-flex flex-column col-12 col-sm-6 col-md-5 col-lg-4 h-100">
        <div class="bs-component" style="flex: 1 1 auto; overflow-y: scroll; height: 50%;">
          <ul id="roster" class="list-group"></ul>
        </div>
        <div class="message d-flex flex-column pt-3" style="flex: 1 1 auto; overflow: hidden; height: 50%;">
          <div class="list-group receive-message" id="receive-message" style="flex: 1 1 auto; overflow-y: scroll;
            border: 1px solid rgba(0, 0, 0, 0.125); background-color: #fff"></div>
          <div class="input-group send-message" style="display:flex;flex:0 0 auto;margin-top:0.2rem">
            <textarea class="form-control shadow-none" id="send-message" rows="1"
              placeholder="Type a message (markdown supported)" style="display:inline-block; width:100%;
              resize:none; border-color: rgba(0, 0, 0, 0.125); outline: none; padding-left: 1.4rem"></textarea>
          </div>
        </div>
      </div>

    -->

      <div class="pinnedVideo">


        <div id="tile-pin" style="display:none" (click)="hideTilePin()">
          <video id="video-pin" class="w-100 h-100"></video>
          <div id="nameplate-pin"></div>
          <!--<button id="video-pause-pin">Pausar</button> -->

        </div>

      </div>

      <div id="tile-container" class="col-12 col-sm-12 col-md-12 col-lg-12 my-4 my-sm-0 h-100 " style="overflow-y: scroll; width: 100%;
      height: 85vh !important;">
        <div id="tile-area" class="w-100 h-100">



          <div id="tile-0" style="display:none" (click)="pinVideo('0')">
            <video id="video-0" class="w-100 h-100"></video>
            <div id="nameplate-0"></div>
            <button id="video-pause-0">Pausar</button>
          </div>
          <div id="tile-1" style="display:none" (click)="pinVideo('1')">
            <video id="video-1" class="w-100 h-100"></video>
            <div id="nameplate-1"></div>
            <button id="video-pause-1">Pausar</button>
          </div>
          <div id="tile-2" style="display:none" (click)="pinVideo('2')">
            <video id="video-2" class="w-100 h-100"></video>
            <div id="nameplate-2"></div>
            <button id="video-pause-2">Pausar</button>
          </div>
          <div id="tile-3" style="display:none" (click)="pinVideo('3')">
            <video id="video-3" class="w-100 h-100"></video>
            <div id="nameplate-3"></div>
            <button id="video-pause-3">Pausar</button>
          </div>
          <div id="tile-4" style="display:none" (click)="pinVideo('4')">
            <video id="video-4" class="w-100 h-100"></video>
            <div id="nameplate-4"></div>
            <button id="video-pause-4">Pausar</button>
          </div>
          <div id="tile-5" style="display:none" (click)="pinVideo('5')">
            <video id="video-5" class="w-100 h-100"></video>
            <div id="nameplate-5"></div>
            <button id="video-pause-5">Pausar</button>
          </div>
          <div id="tile-6" style="display:none" (click)="pinVideo('6')">
            <video id="video-6" class="w-100 h-100"></video>
            <div id="nameplate-6"></div>
            <button id="video-pause-6">Pausar</button>
          </div>
          <div id="tile-7" style="display:none" (click)="pinVideo('7')">
            <video id="video-7" class="w-100 h-100"></video>
            <div id="nameplate-7"></div>
            <button id="video-pause-7">Pausar</button>
          </div>
          <div id="tile-8" style="display:none" (click)="pinVideo('8')">
            <video id="video-8" class="w-100 h-100"></video>
            <div id="nameplate-8"></div>
            <button id="video-pause-8">Pausar</button>
          </div>
          <div id="tile-9" style="display:none" (click)="pinVideo('9')">
            <video id="video-9" class="w-100 h-100"></video>
            <div id="nameplate-9"></div>
            <button id="video-pause-9">Pausar</button>
          </div>
          <div id="tile-10" style="display:none" (click)="pinVideo('10')">
            <video id="video-10" class="w-100 h-100"></video>
            <div id="nameplate-10"></div>
            <button id="video-pause-10">Pausar</button>
          </div>
          <div id="tile-11" style="display:none" (click)="pinVideo('11')">
            <video id="video-11" class="w-100 h-100"></video>
            <div id="nameplate-11"></div>
            <button id="video-pause-11">Pausar</button>
          </div>
          <div id="tile-12" style="display:none" (click)="pinVideo('12')">
            <video id="video-12" class="w-100 h-100"></video>
            <div id="nameplate-12"></div>
            <button id="video-pause-12">Pausar</button>
          </div>
          <div id="tile-13" style="display:none" (click)="pinVideo('13')">
            <video id="video-13" class="w-100 h-100"></video>
            <div id="nameplate-13"></div>
            <button id="video-pause-13">Pausar</button>
          </div>
          <div id="tile-14" style="display:none" (click)="pinVideo('14')">
            <video id="video-14" class="w-100 h-100"></video>
            <div id="nameplate-14"></div>
            <button id="video-pause-14">Pausar</button>
          </div>
          <div id="tile-15" style="display:none" (click)="pinVideo('15')">
            <video id="video-15" class="w-100 h-100"></video>
            <div id="nameplate-15"></div>
            <button id="video-pause-15">Pausar</button>
          </div>
          <div id="tile-16" style="display:none" (click)="pinVideo('16')">
            <video id="video-16" class="w-100 h-100"></video>
            <div id="nameplate-16"></div>
            <button id="video-pause-16" class="btn">Pausar</button>
          </div>
          <div id="tile-17" style="display:none" (click)="pinVideo('17')">
            <video id="video-17" class="w-100 h-100"></video>
            <div id="nameplate-17"></div>
            <button id="video-pause-17" class="btn">Pausar</button>
          </div>
        </div>
      </div>
      <video id="content-share-video" crossOrigin="anonymous" style="display:none"></video>
    </div>
  </div>
</div>

<!-- Failure card if meeting cannot be joined -->

<div id="flow-failed-join" class="flow" style=" display: none ;">
  <div class="container">
    <form id="form-failed-join">
      <div class="card border-warning mb-3" style="max-width: 20rem;">
        <div id="failed-join" class="card-header"></div>
        <div class="card-body">
          <h4 class="card-title">No es posible encontrar esta conferencia</h4>
          <p class="card-text">Hay un conflicto entrando a la sala, vuelve a intentar de nuevo,</p>
          <small id="failed-join-error" class="text-muted"></small>
        </div>
      </div>
      <button class="btn btn-lg btn-outline-warning btn-block" type="submit">OK</button>
    </form>
  </div>
</div>