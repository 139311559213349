import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NotifyService } from '../../services/notify.service';
import { LoginService } from '../../services/login.service'
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ReportsService } from '../../services/reports.service';
import { PermissionsService } from './../../services/permissions.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: string;
  loading: boolean = false;
  errorM: string;
  load: boolean = false;
  phoneLogin: boolean = false;
  tokenGraphql = environment.token;
  errorPassword: any = "Las contraseñas no coinciden";
  loginForm: FormGroup;
  phoneForm: FormGroup;
  forgotPassword: FormGroup;
  recovery: boolean = false;
  validate_recovery: boolean = false;
  newPassword: FormGroup;
  password: boolean;
  language = localStorage.getItem("language")
  error_messages: any;
  user;
  showPass: boolean = false;
  showPassRecovery: boolean = false;
  dataPermission = {};
  error_messages_es = {
    'password': [
      { type: 'required', message: 'La contraseña es requirida' },
      { type: 'minlength', message: 'La contraseña debe tener mínimo 8 carácteres.' },
      { type: 'maxlength', message: 'La contraseña debe tener máximo 30 carácteres.' },
      { type: 'pattern', message: 'La contraseña debe contener al menos 1 mayúscula y 1 número.' }
    ],
    'user': [
      { type: 'required', message: 'Es requerido el email' },
      { type: 'email', message: 'Debes digitar un email' },

    ],
    'phone': [
      { type: 'required', message: 'Es requerido el telefono' },
      { type: 'pattern', message: 'El numero debe tener prefijo del pais ejemplo +57' }
    ],
    code: [
      { type: 'required', message: 'El codigo es necesario ' },

    ],
  };

  error_messages_pt = {
    'password': [
      { type: 'required', message: 'A senha é requerida' },
      { type: 'minlength', message: 'A senha deve ter no mínimo 8 caracteres' },
      { type: 'maxlength', message: 'A senha deve ter no máximo 30 caracteres' },
      { type: 'pattern', message: 'A senha deve conter pelo menos 1 letra maiúscula e 1 número' }
    ],
    'user': [
      { type: 'required', message: 'E-mail é obrigatório' },
      { type: 'email', message: 'Você deve digitar um email' }
    ],
    'phone': [
      { type: 'required', message: 'O telefone é obrigatório' },
      { type: 'pattern', message: 'O número deve ter um exemplo de código de país +57' }
    ],
    code: [
      { type: 'required', message: 'o código é necessário' },

    ],
  };


  constructor(private router: Router, private _loginService: LoginService,
    private formBuilder: FormBuilder,
    private _notify: NotifyService,
    private _reports: ReportsService,
    private activatedRouter: ActivatedRoute,
    private valuePermissions: PermissionsService

  ) {

    this.forgotPassword = this.formBuilder.group({


      user_name: new FormControl('', Validators.compose([
        Validators.required,

      ])),


    }, {

    });


    this.newPassword = this.formBuilder.group({

      code: new FormControl('', Validators.compose([
        Validators.required,

      ])),
      confirm_password: new FormControl('', Validators.compose([
        Validators.required,

      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/)
      ])),
    }, {

    });
    this.loginForm = this.formBuilder.group({
      user: new FormControl('', Validators.compose([
        Validators.required,
        Validators.email
      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/)
      ])),
    }, {

    });



    this.phoneForm = this.formBuilder.group({
      phone: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(/^(?=.*[1-9])(?=.*[+])/)

      ])),
      password: new FormControl('', Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30),
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/)
      ])),
    }, {

    });

  }

  ngOnInit() {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        //console.log("event");
        // if (!!event.url && event.url.match(/^\/#/)) {
        //   this.router.navigate([event.url.replace('/#', '')]);
        // }
      }
    });


    this.activatedRouter.queryParams.subscribe(params => {
      let userNameTwo: string = null;
      //console.log("params", params);
      if (params['userId'] && params['token']) {

        this.loading = true;
        const credentials = {
          input: {
            userName: params['userId'],
            accessToken: params['token']
          }
        };

        if (!credentials.input.userName.includes('@')) {
          if (typeof parseInt(credentials.input.userName) === 'number') {
            let userNameUri: string = encodeURIComponent(credentials.input.userName);
            userNameTwo = userNameUri.replace('%20', '+');
            credentials.input.userName = userNameTwo;
          };
        }

        this._loginService.Login(credentials).then(async res => {
          if (await res.status === "200") {
            this.getAzureToken();
            //console.log("res login token", res);
            const dynamon_user = res.body;
            localStorage.setItem("dynamo_user", JSON.parse(JSON.stringify(dynamon_user)));
            localStorage.setItem("auth", this.tokenGraphql);
            localStorage.removeItem("last_user_historic");
            this.router.navigate(['/landing']);
            this._notify.success("Bienvenido...");
          } else if (res.error == "NotAuthorizedException") {
            this.loading = false;
            this._notify.error("Las credenciales son erroneas");
            this.loginForm.controls.password.reset()
          } else if (res.message.name == "UserNotConfirmedException") {
            this.loading = false;
            this._notify.error("El usuario no ha sido activado");
          } else if (res.message == "The user status is inactive") {
            this.loading = false;
            this._notify.error("Usuario inactivo contacta al administrador");
          } else {
            this.loading = false;
            this._notify.error("no se puede validar el usuario");

          }

        }, err => {
          this.loading = false;
          //console.log("error", err);
        })

      } else if (params['userId']) {
        const userName = params['userId'];
        this.user = userName;
        this.loginForm.controls['user'].setValue(userName);
        this.phoneForm.controls['phone'].setValue(userName);
      } else {
        this.router.navigateByUrl('/login');
      }
    });


    if (localStorage.getItem("language")) {
      if (localStorage.getItem("language") == "es") {
        this.error_messages = this.error_messages_es
      } else if (localStorage.getItem("language") == "pt") {
        {
          this.error_messages = this.error_messages_pt;

        }
      }

    } else {
      this.error_messages = this.error_messages_es;
      localStorage.setItem("language", "es");
      this.language = "es";

    }

    if (this._loginService.estaAutenticado()) {
      this.router.navigateByUrl('/landing/home');
    }


  }



  showPassword() {
    this.showPass = !this.showPass;
  }

  showPassReco() {
    this.showPassRecovery = !this.showPassRecovery;
  }

  recoveryModale() {
    this.recovery = !this.recovery;
  }

  recoveryPassword() {
    this.loading = true;


    if (this.forgotPassword.valid == true) {
      const credentials = {

        username: this.forgotPassword.value.user_name
      }
      setTimeout(() => {
        this._loginService.forgotPassword(credentials).subscribe(res => {

          this.validate_recovery = true;
          this.recovery = false;
          this.loading = false;
          this._notify.success("Enviamos un codigo de verificación a " + this.forgotPassword.value.user_name + "  para que puedas cambiar tu contraseña");

        }, err => {
          this.loading = false;

          if (err.error.message.name == "LimitExceededException") {
            this._notify.error("Lo sentimos superaste el limite de envios intentalo más tarde ")
          }
        })

      }, 1000);

    } else {

      setTimeout(() => {
        this.loading = false;
        this._notify.error("Debes digitar tu usuario");
      }, 1000);
    }
  }



  changuePass() {
    if (this.newPassword.valid == true) {

      const credentials = {
        username: this.forgotPassword.value.user_name,
        verificationCode: this.newPassword.value.code,
        password: this.newPassword.value.password,
      }
      this._loginService.newpass(credentials).subscribe(res => {

        this._notify.success("Se cambio tu contraseña con exito");
        this.recovery = false;
        this.validate_recovery = false;


      }, err => {

        if (err.error.message.name == "ExpiredCodeException") {
          this._notify.error("Lo sentimos este codigo ya fue usado");
        }

        if (err.error.message.name == "CodeMismatchException") {
          this._notify.error("Verifica el codigo este no es correcto");
        }

      })
    }


  }
  loginMethodPhone() {

    this.loading = true;
    if (this.phoneForm.valid == true) {
      const credentials = {
        input: {
          userName: this.phoneForm.value.phone,
          password: this.phoneForm.value.password
        }
      };


      this._loginService.Login(credentials).then(async res => {
        if (await res.status === "200") {
          this.getAzureToken();
          const dynamon_user = res.body;
          localStorage.setItem("dynamo_user", JSON.parse(JSON.stringify(dynamon_user)));
          localStorage.setItem("auth", this.tokenGraphql);
          localStorage.removeItem("last_user_historic");
          this.router.navigate(['/landing']);
          this._notify.success("Bienvenido...");
        } else if (res.error == "NotAuthorizedException") {
          this.loading = false;
          this._notify.error("Las credenciales son erroneas");

          this.loginForm.controls.password.reset()
        } else if (res.message.name == "UserNotConfirmedException") {
          this.loading = false;
          this._notify.error("El usuario no ha sido activado");
        } else if (res.message == "The user status is inactive") {
          this.loading = false;
          this._notify.error("Usuario inactivo contacta al administrador");

        } else {
          this.loading = false;
          this._notify.error("no se puede validar el usuario");
        }




      }, err => {
        this.loading = false;

        //console.log("error", err);

      });

    } else {

      if (this.phoneForm.controls.password.valid == false) {
        this._notify.error("La contraseña no cumple los requisitos o no la has escrito")
      }
      if (this.phoneForm.controls.phone.valid == false) {
        this._notify.error("El telefono no es valido o no lo has escrito")
      }
    }
  }


  loginMethod(userId?: string, pass?: string, token?: string) {


    if (this.loginForm.controls.user.valid == true && this.loginForm.controls.password.valid == true) {
      this.loading = true;
      const credentials = {
        input: {
          userName: this.loginForm.value.user.toLowerCase(),
          password: this.loginForm.value.password
        }
      };


      this._loginService.Login(credentials).then(async res => {
        if (await res.status === "200") {
          this.getAzureToken();
          //console.log("res login", res);
          const dynamon_user = res.body;
          localStorage.setItem("dynamo_user", JSON.parse(JSON.stringify(dynamon_user)));
          localStorage.setItem("auth", this.tokenGraphql);
          localStorage.removeItem("last_user_historic");
          this.router.navigate(['/landing']);
          this._notify.success("Bienvenido...");
        } else if (res.error == "NotAuthorizedException") {
          this.loading = false;
          this._notify.error("Las credenciales son erroneas");
          this.loginForm.controls.password.reset()
        } else if (res.message.name == "UserNotConfirmedException") {
          this.loading = false;
          this._notify.error("El usuario no ha sido activado");
        } else if (res.message == "The user status is inactive") {
          this.loading = false;
          this._notify.error("Usuario inactivo contacta al administrador");
        } else {
          this.loading = false;
          this._notify.error("no se puede validar el usuario");

        }

      }, err => {
        this.loading = false;
        //console.log("error", err);
      })

    } else {
      this.loading = false;
      this._notify.error("no se puede validar el usuario");
    }
  }



  getAzureToken() {

    this._reports.getAzureToken().subscribe(res => {
      //console.log("res token", res);
      var newData = JSON.parse(res.data.azureToken);
      localStorage.setItem("azureToken", newData.access_token)



    });

  }

  validatePassword(event) {
    if (this.newPassword.value.password == event) {
      this.errorPassword = "Las contraseñas coinciden",
        this.password = true;

    } else {
      this.errorPassword = "Las contraseñas no coinciden",
        this.password = false;
    }

  }

}
