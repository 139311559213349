import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdministrationService } from '../../../services/administration.service';
import { APIService } from '../../../API.service.service';
import { Router } from '@angular/router';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ChimeService } from '../../../services/chime.service';
import { UtilsService } from '../../../services/utils.service';
import { FileSaverService } from 'ngx-filesaver';
import { HttpHeaders } from '@angular/common/http';
import { AmazonService } from '../../../services/amazon.service';
import { PermissionsService } from './../../../services/permissions.service';


import Swal from 'sweetalert2'
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-whiteboard-all',
  templateUrl: './whiteboard-all.component.html',
  styleUrls: ['./whiteboard-all.component.css']
})
export class WhiteboardAllComponent implements OnInit {

  @Input() validatePage: string;
  dynamo_user = JSON.parse(localStorage.getItem("dynamo_user"));
  notFound: any;
  loader: any;
  usersDataForModal: any[] = [];
  chimeGroups = [];
  showVar: boolean = false;
  _value: number = 0;
  createVideocall: boolean = false;
  dataPermission = {};
  placa: string;
  type: string;

  inWhiteboard = true;
  dataFilterWhiteboard: any[];
  isChecked: boolean = true;
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  tokenData = localStorage.getItem("auth");

  inCreateModal = false;

  conferenceName = "";

  modalOption: NgbModalOptions = {}; // not null!

  user = JSON.parse(localStorage.getItem("dynamo_user"));


  isWhiteBoard = true;

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'video/mp4',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(
    public _admin: AdministrationService,
    public _chimeService: ChimeService,
    public api: APIService,
    private modalService: NgbModal,
    private _route: Router,
    private _util: UtilsService,
    private fileSaverService: FileSaverService,
    private _amazonService: AmazonService,
    private valuePermissions: PermissionsService,
    router: Router

  ) {

    //console.warn("??????????????????")

    var _prev = localStorage.getItem("previousVideocall");

    if (_prev) {

      var prev = JSON.parse(_prev);


      var newRes = prev;
      newRes.enterprise = this.user.company_id
      // newRes.isFromPrevious = true;


      this._route.navigate(['/landing/videocall', prev.Id], { queryParams: prev.params });
    }


    /*
        router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log('prevVVVVVV:', event.url);
      
        });
        */

  }

  ngOnInit() {
    this.dataPermission = this.valuePermissions.getSpecificModule('VideoLi');
  }

  ngOnChanges() {

    this.getWhiteboards();


  }



  addChimeGroup() {

    //console.log("userLocal", this.dynamo_user);

    var query: any = {

      input: {
        name: this.conferenceName.trim(),
        userId: this.user.id,
        company: this.user.company_id,
        users: [],
        whiteBoard: "",
        whiteBoardUrl: ""
      }

    };

    if (this.isWhiteBoard) {
      query.input.whiteBoard = "true";
    }

    if (this.conferenceName.trim().length > 0) {
      this._chimeService.createChimeGroup(query).then((res) => {
        //console.log("resss!", res);
        this.inCreateModal = false;


        var urlWhitBoard = `https://backendstack-az-bucket.s3.amazonaws.com/index.html?companyId=${this.dynamo_user.company_id}&groupId=${res.Id}&meetingName=${this.conferenceName.trim()}&userName=${this.dynamo_user.name}&userId=${this.dynamo_user.username}&accessToken=${this.dynamo_user.access_token}&idToken=${this.dynamo_user.id_token}&refreshToken=${this.dynamo_user.refresh_token}`

        //console.log("urlWhitBoard,", urlWhitBoard);
        // res.isFromPrevious = false;
        res.showMenuConfig = true;



        if (this.isWhiteBoard) {
          this.getWhiteboards();
          this.inCreateModal = false;
          window.open(urlWhitBoard);
        }

        else {
          this._route.navigate(['/landing/videocall', res.Id], { queryParams: res });
          this.getWhiteboards();
        }


      })
        .catch((err) => {

          this.inCreateModal = false;

          //console.log("error creating chime group", err);
        });
    } else {
      Swal.fire(
        '',
        'Por favor ingresa el nombre de la reunión',
        'warning'
      )
    }





  }

  onFloatingButtonClick() {
    this.inCreateModal = !this.inCreateModal;
  }

  prueba(chime) {
    //console.log("prueba");
    //console.log("prueba chime", chime);
    //console.log("chime", chime.whiteBoard)
    if (chime.whiteBoard) {

      //console.log("Entrooooo!!")

      //console.log("myurl", chime.whiteBoardUrl)
      window.open(chime.whiteBoardUrl);
    }
    else {

      //[routerLink]="['/landing/videocall', chime.Id]"
      this._route.navigate(['/landing/videocall', chime.Id], { queryParams: chime });
      //this._route.navigateByUrl('landing/videocall/view');
    }
    // 
    this.showVar = true;
  }


  onSuccess(event) {
    //console.log("event??", event);
  }



  onErr(event) {
    //console.log("error event??", event);
  }


  getWhiteboards() {
    let status = JSON.parse(localStorage.getItem("statusInactiveUsers"));
    this.dataFilterWhiteboard = [];
    if (status === 'tab1') {
      this.loader = true;


      const queryChimeGroups = {
        company: this.user.company_id,
      }


      this._chimeService.getChimeGroups(queryChimeGroups).then(async (res) => {

        let dataUser = {
          Id: this.user.id,
          company: this.user.company_name
        }

        this._chimeService.getUser(dataUser).then(async dataUser => {
          //console.log("data user", await dataUser);
          var newData = [];
          this.type = this.dynamo_user.groups[0].name;
          localStorage.setItem("type", this.type.toLowerCase());
          if (dataUser[0].placa) {
            localStorage.setItem("placa", dataUser[0].placa);
            this.placa = dataUser[0].placa;



            //console.log("res getChimeGroups", res)

            res.map(item => {
              item.showMenuConfig = true;
              if (this.placa === item.name) {
                newData.push(item);
              }
              // if (item.status == validate && item.Id != this.dynamo_user.Id) {
              // }
              if (localStorage.getItem("type") === "principal" ||
                localStorage.getItem("type") === "contact center" ||
                localStorage.getItem("type") === "perito" ||
                localStorage.getItem("type") === "admin" ||
                localStorage.getItem("type") === "administrador" ||
                localStorage.getItem("type") === "client") {
                newData.push(item);

              } else {
                if (this.type === "principal" || this.type === "contact center"
                  || this.type === "perito" || this.type === "admin" || this.type === "administrador" ||
                  this.type === "client") {
                  newData.push(item);
                }
              }

            });
            if (newData.length > 0) {
              this.dataFilterWhiteboard = newData.filter(data => data.whiteBoard == "true");
            };
            this.chimeGroups = newData;
            //console.log("this.chimegroups", this.chimeGroups);


            setTimeout(() => {

              this.loader = false;
              if (this.chimeGroups.length == 0) {
                this.notFound = true;
              } else { this.notFound = false }


            }, 1500);

          }

          //console.log("res getChimeGroups", await res)
          var newData = []

          res.map(item => {
            item.showMenuConfig = true;
            if (this.placa === item.name) {
              //console.log("item whiteboard 111", item.whiteBoard);
              if (item.whiteBoard == "true") {
                newData.push(item);
              }
            }
            // if (item.status == validate && item.Id != this.dynamo_user.Id) {
            // }
            if (localStorage.getItem("type") === "principal" ||
              localStorage.getItem("type") === "contact center" ||
              localStorage.getItem("type") === "perito" ||
              localStorage.getItem("type") === "admin" ||
              localStorage.getItem("type") === "administrador" ||
              localStorage.getItem("type") === "client") {
              newData.push(item);

            } else {
              if (this.type === "principal" || this.type === "contact center"
                || this.type === "perito" || this.type === "admin" || this.type === "administrador" ||
                this.type === "client") {
                newData.push(item);
              }
            }

          });
          if (newData.length > 0) {
            this.dataFilterWhiteboard = newData.filter(data => data.whiteBoard == "true");
          };
          this.chimeGroups = this.dataFilterWhiteboard;
          //console.log("this.chimegroups", this.chimeGroups);


          setTimeout(() => {

            this.loader = false;
            if (this.chimeGroups.length == 0) {
              this.notFound = true;
            } else { this.notFound = false }


          }, 1500);


        });

      }, err => {

        //console.log("err", err)

        this.loader = false;
        this.notFound = true;


      })


    }
  }

  eliminarGroup(chime, i) {
    //console.log("chime", chime)
    //console.log("click aqui", i)
    let dataDelete = {
      input: {
        Id: chime.Id,
        company: chime.company
      }

    }
    this._chimeService.deleteChimeGroup(dataDelete).then((res) => {
      //console.log("res deleteChimeGroup", res);
      this.getWhiteboards();
    });

  }


}
