<div class="general-chat">


    <div class="cont-chat">

        <!-- <div class="users-chat">
            <app-users-conference></app-users-conference>
        </div> -->
        <!-- <router-outlet></router-outlet> -->
        <!-- <app-landing (showVar)="showVar" class="conversation"></app-landing> -->

        <app-users-sessions  class="conversation"></app-users-sessions>

        <!-- <app-modal-create-chime-group class="conversation"></app-modal-create-chime-group> -->




        <!-- <div class="conversation">
            <app-chat-conversation></app-chat-conversation>

        </div> -->
        <!-- <span class="vl"></span> -->

        
    </div>
    
    
    
    
</div>