import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from "../../environments/environment"



@Injectable({
  providedIn: 'root'
})

export class AdministrationService {
  zeus: string = environment.zeus;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(public http: HttpClient) { }



  activateZeusUser(user: any) {

    console.log("user is:", user);
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<any>(this.zeus + 'confirmRegistration', user, httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  getReportZeus(report: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };

    return this.http.get<any>(this.zeus + "getReports/" + JSON.stringify({ "pageSize": report.pageSize, "Id": report.Id, "company": report.company }), httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }


  getGroups(params) {

    return this.http.get<any>(this.zeus + "getGroups/" + JSON.stringify(params), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteGroup(group){

console.log("delete",group)
    return this.http.delete<any>(this.zeus + 'deleteGroup/'+ JSON.stringify(group), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    );
  }
  addZeusUser(user: any) {

    return this.http.post<any>(this.zeus + 'signUp', user, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  updateGroup(group) {
    console.log("group in service",group);

    return this.http.post<any>(this.zeus + 'updateGroup', JSON.stringify(group), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  addZeusUserClient(user: any) {
    console.log("credentials", user)

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(this.zeus + 'signUpNoConfirmTM', user, httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  updateUser(user) {

    console.log("params",user)


    return this.http.post<any>(this.zeus + 'updateUser', user, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }

  createGroup(params) {


    return this.http.put<any>(this.zeus + 'insertGroup', JSON.stringify(params), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );


  }

  getUsersZeus(data: any) {

    return this.http.get<any>(this.zeus + 'getUsers/' + JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );

  }



  private handleError(error: HttpErrorResponse) {
    console.log(error)

    if (error.error instanceof ErrorEvent) {
      throw error;
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(error);
  }
}
