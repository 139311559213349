import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomePageComponent } from './components/home-page/home-page.component'
import { HomeComponent } from './components/home/home.component'
import { AuthGuard } from './guards/auth.guard';
import { ManageFormComponent } from './components/forms/manage-form/manage-form.component';
import { HomeManageFormComponent } from './components/home-manage-form/home-manage-form.component';
import { VideocallComponent } from './components/videocall/videocall.component';
import { LandingComponent } from './components/videocall/landing/landing.component';
import { WhiteboardComponent } from './components/whiteboard/whiteboard.component';
import { AuthVideocallGuard } from './guards/authvideocall.guard';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: ':userId', component: LoginComponent },
  { path: '', component: LoginComponent },
  {
    path: 'landing',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: HomeManageFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'forms/manageForm/:id',
        component: ManageFormComponent,
        canActivate: [AuthGuard],
      },

      {
        path: 'forms/manageForm',
        component: ManageFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'videocall',
        component: VideocallComponent,
        // 
      },
      {
        path: 'videocall/:id',
        component: LandingComponent,
        canActivate: [AuthVideocallGuard]

      },
      {
        path: 'whiteboard',
        component: WhiteboardComponent,
        canActivate: [AuthVideocallGuard]

      },
    ],
  },
  // {
  //   path: '**',
  //   redirectTo: '/login',
  //   pathMatch:'full'
  // }

  // {
  //   path: '**',
  //   redirectTo: '/login',
  //   pathMatch:'full'
  // }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes),
  RouterModule.forRoot(appRoutes, { useHash: true }),

  ],
  exports: [RouterModule],
  providers: [

  ]
})
export class AppRoutingModule { }
