import { Component, OnInit } from '@angular/core';
import { PermissionsService } from './../../../services/permissions.service';

@Component({
  selector: 'app-whiteboard-sessions',
  templateUrl: './whiteboard-sessions.component.html',
  styleUrls: ['./whiteboard-sessions.component.css']
})
export class WhiteboardSessionsComponent implements OnInit {

  validatePage: string;
  tittle: string;
  visualizeLash: boolean = false;
  dataPermission = {};


  constructor(private valuePermissions: PermissionsService) { }

  ngOnInit() {
    this.dataPermission = this.valuePermissions.getSpecificModule('VideoLi');
    this.validateUsersStatus();
    this.changueUsersStatus(this.validatePage);
    //console.log("title?", this.tittle);

  }

  ngOnChanges() {
    this.changueUsersStatus('');
    //console.log("title?", this.tittle);
  }



  changueUsersStatus(data: string) {
    //console.log("data", data);
    switch (data.length > 0) {
      case data === 'tab1':
        this.validatePage = 'tab1';
        this.tittle = 'panel interactivo';
        break;
    }
    //console.log("change status", this.validatePage);
    localStorage.setItem("statusInactiveUsers", JSON.stringify(this.validatePage));

    // this.updateUsers();


  }

  validateUsersStatus() {


    if (localStorage.getItem("statusInactiveUsers")) {

      this.validatePage = JSON.parse(localStorage.getItem("statusInactiveUsers"));
      //console.log("the validate", this.validatePage);
    } else {
      this.validatePage = 'tab1';
      localStorage.setItem("statusInactiveUsers", "tab1");
    }
  }

}
