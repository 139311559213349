import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ChimeService } from '../../../services/chime.service';
import { getUser } from '../../../graphql/form';
import { UtilsService } from '../../../services/utils.service';

@Component({
  selector: 'app-modal-create-chime-group',
  templateUrl: './modal-create-chime-group.component.html',
  styleUrls: ['./modal-create-chime-group.component.css']
})
export class ModalCreateChimeGroupComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() name;
  forma: FormGroup;
  isSubmitted = false;
  City: any = [];
  listUsers: any[] = [];
  seleccionado: any;
  newArray: any[] = [];
  select: any;


  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public _chimeService: ChimeService,
    private _util: UtilsService) {
    this.createForm();

  }



  ngOnInit() {
    this.getUser();
    this.getUsers();

  }

  ngOnChanges() {

  }

  ngAfterViewInit() {

  }

  get nombreReunion() {
    return this.forma.get('nombreReunion').invalid && this.forma.get('nombreReunion').touched
  }

  get reunionNameValue(): string {
    return this.forma.get('nombreReunion').value
  }

  get usuarios() {
    return this.forma.get('usuarios');
  }

  createForm() {
    this.forma = this.formBuilder.group({
      Id: ['048b12d0-6f36-412a-9d37-b6dd088f4508'],
      nombreReunion: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(15)]],
      usuarios: ['', [Validators.required]],
      list: this.formBuilder.array([]),
      company: ['1340'],
    });
  }


  changeUsuario(e) {
    //console.log("change usuario", this.seleccionado);
    this.select = this.seleccionado.emailUser;
    //console.log("select?", this.select)
    this.usuarios.setValue(e.target.value, {
      onlySelf: true
    });
  }

  submitForm() {
    this.isSubmitted = true;
    if (!this.forma.valid) {
      return false;
    } else {
      //console.log("forma", this.forma.value)
      this.activeModal.close(this.forma.value);
      // this.forma.reset({
      //   nombreReunion: ''
      // })
    }
  }



  getUser() {

    let data = JSON.parse(localStorage.getItem('dynamo_user'));
    let email = data.email;
    const dataSend = {
      Id: '048b12d0-6f36-412a-9d37-b6dd088f4508',
      company: "968"
    }
    //console.log("object to send", dataSend);
    this._chimeService.getUser(dataSend).then((res) => {
      //console.log("res getuser", res);
    });
  }

  getUsers() {
    let acaData: any[];
    this.listUsers = [];
    //console.log("entra al get users");
    const query = {
      // where: { group: this.groupSelected.Id }
      // userId: 'COLSERAUTOS@AZLOGICA.COM',
      company: '1340'
    }
    // this._chimeService.getUsers(dataSend).then((res)=> console.log("res get users",res));


    this._chimeService.getSessions(query).then((res) => {
      acaData = res;
      acaData.forEach(value => {
        this.listUsers.push({
          emailUser: value.userId
        });
      })
      //console.log("listUsers", this.listUsers);
    });
  }

  addCourse(select: string) {
    //console.log("the select en add", this.select);
    this.newArray.indexOf(this.select) === -1 ? this.newArray.push(this.select) : console.log("el elemento ya existe");;

    // this.forma.get('list').setValue(this.select);


    this.forma.setControl('list', this.formBuilder.array(this.newArray || []));
    //console.log("the newer array", this.newArray);

  }

  createGroup() {
    const object = {
      input: {
        userId: '048b12d0-6f36-412a-9d37-b6dd088f4508',
        company: '1340',
        name: this.forma.value['nombreReunion'],
        users: this.forma.value['list']
      }
    }

    // delete this.forma.value['usuarios'];

    // //console.log("the object",object);

    this._chimeService.createChimeGroup(object).then((res) => {
      //console.log("respuesta createChime", res);
    })
  }


}
