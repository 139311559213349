import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from '../../services/notify.service';
import { PermissionsService } from './../../services/permissions.service';

@Component({
  selector: 'app-home-manage-form',
  templateUrl: './home-manage-form.component.html',
  styleUrls: ['./home-manage-form.component.css']
})
export class HomeManageFormComponent implements OnInit {
  dataPermissionWeb = {};
  dataPermissionVideo = {};
  dataPermissionWhiteboard = {};


  constructor(private router: Router, private _notify: NotifyService,
    private valuePermissions: PermissionsService) { }

  ngOnInit() {
    this.dataPermissionWeb = this.valuePermissions.getSpecificModule("FormWeb");
    this.dataPermissionVideo = this.valuePermissions.getSpecificModule("VideoLi");
    this.dataPermissionWhiteboard = this.valuePermissions.getSpecificModule('Whiteboard');
  }

  closeSesion() {

    this._notify.closeSesion("Vuelve pronto", 2000);
    setTimeout(() => {
      localStorage.removeItem("azureToken");
      localStorage.removeItem("last_user_historic");
      localStorage.removeItem("graphToken");
      localStorage.removeItem("actualPage");
      localStorage.removeItem("dynamo_user");
      localStorage.removeItem("evolucion_user");
      localStorage.removeItem("auth");
      localStorage.removeItem("EmpresaNombre");
      localStorage.removeItem("Id");
      localStorage.removeItem("Empresa");
      localStorage.removeItem("TZ");
      localStorage.removeItem("Alias");
      localStorage.removeItem("Placa");
      localStorage.removeItem("GrupoNombre");
      this.router.navigate(["login"]);
    }, 2500);

  }

  changeRoute(route) {
    console.log("route", route);
    if (route === 'mForm') {
      this.router.navigate(['landing/forms/manageForm']);
    } else if (route === "vCall") {
      this.router.navigate(['landing/videocall']);
    } else {
      this.router.navigate(['/landing/whiteboard']);
    }
  }

}
