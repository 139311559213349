import { Component, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { pipe, Subject } from "rxjs";
import { ChimeService } from '../../../services/chime.service';
import { UtilsService } from '../../../services/utils.service';
import { AmazonService } from '../../../services/amazon.service';
import { NgxSpinnerService } from "ngx-spinner";
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import Swal from 'sweetalert2';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { NotifyService } from '../../../services/notify.service';
import { PermissionsService } from './../../../services/permissions.service';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'app-manage-form',
  templateUrl: './manage-form.component.html',
  styleUrls: ['./manage-form.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class ManageFormComponent implements OnInit, OnChanges, AfterContentInit {

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  onDestroy$ = new Subject<void>();
  model: any = {};
  data: any[];
  dataForm: any[];
  dataAll: any[];
  arrayDataTwo: any[] = [];
  dataKeyAndUrl: any[] = [];
  arrayOfModal: any[];
  arrayTest: any[] = [];
  arrayImage: any[] = [];
  dataUserPosition: { latitude: string; longitude: string };
  disabled: boolean = false;



  dataUser: {} = JSON.parse(localStorage.getItem('dynamo_user'));
  dataPermission: any;
  hidenForm: boolean = false;

  activedStep = 0;

  steps: StepType[] = [];

  form = new FormArray(this.steps.map(() => new FormGroup({})));

  options = this.steps.map(() => <FormlyFormOptions>{});


  constructor(private _chimeService: ChimeService,
    private _amazonService: AmazonService,
    private _utils: UtilsService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private _notify: NotifyService,
    private valuePermissions: PermissionsService) {

    // this._utils.observable.subscribe(data => {
    //   console.log("data example", data);
    // });

  }


  get f() {
    return this.form.controls;
  }

  ngOnInit() {
    this.validatePermission();
    this.getUserPosition();
    this._chimeService.getFormForSpecificId({ company: this.dataUser['company_id'] }).then(async (res: any[]) => {
      //console.log("resss", res);
      const dataForm = await res.filter(data => data.type === '6');
      this.setFormId(dataForm[0]);
    }).catch(err => {
      console.error(err);
    });


    this._utils.observableImage.subscribe(data => {
      let index = this.arrayImage.findIndex(dataAr => dataAr.key === data.key);
      if (index === -1) {
        this.arrayImage.push(data);
      } else {
        this.arrayImage[index] = data;
      }
    });



  }

  validatePermission() {
    this.dataPermission = this.valuePermissions.getSpecificModule('FormWeb');
    if (this.dataPermission.additional.disableForm != undefined
      && !this.dataPermission.additional.disableForm) {
      this.hidenForm = true;
    } else {
      this.hidenForm = false;
    }
    //console.log("hidden form", this.hidenForm);
    //console.log("dataPermission manage form", this.dataPermission);
  }

  ngOnChanges() {
    //console.log("cambio data array", this.dataKeyAndUrl);

  }

  ngAfterContentInit() {
    this.disabled = true;

  }

  isValid() {
    Swal.fire({
      icon: 'info',
      text: 'Por favor, completa todos los campos',
    });
  }


  getUserPosition() {

    this._utils.getUserPosition().then(async res => {
      //console.log("entra get user position");
      this.dataUserPosition = {
        latitude: await res.coords.latitude,
        longitude: await res.coords.longitude
      }
      //console.log("this.dataUserPosition err 1", this.dataUserPosition);
    }).catch(err => {
      this.dataUserPosition = {
        latitude: "0",
        longitude: "0"
      }
      //console.log("this.dataUserPosition err", this.dataUserPosition);
      console.error(err);

    });

    //console.log("this.dataUserPosition", this.dataUserPosition);


  }



  setFormId(dataForm) {
    this.spinner.show('loader');
    this.model = {};
    let contador: number = 0;
    let dataQuery = {
      Id: dataForm.Id,
      company: this.dataUser['company_id']
    };

    setTimeout(() => {
      this._chimeService.getFormId(dataQuery).then(async (res) => {
        //console.log("");
        //console.log("res men", res);
        this.dataAll = await res[0];
        //console.log("data All", this.dataAll);
        this.data = await JSON.parse(res[0].paramsData);
        this.arrayDataTwo = await this.data['paths']['/tablas'].post.parameters;
        this.dataForm = await this.data['paths']['/tablas'].post.parameters;
        //console.log("data form 1", this.dataForm);

        // console.log("data manage", data);
        // this.dataForm = data.paths['/tablas'].post.parameters;
        // console.log("data form", this.dataForm);

        this.dataForm.forEach((data, i) => {

          if (this.dataPermission.additional != {} && this.dataPermission.additional.disableForm) {
            if (data.type === 'separator' || data.type === 'button') {
              return;
            }

            if (data.type === 'foto' || data.type === 'file' || data.type === 'image') {
              this.steps[contador - 1]['fields'].push({ key: data.id, type: "file", wrappers: ['form-field'], templateOptions: { label: data.name, required: true, type: 'file', disabled: true } });
            }



            if (data.type === 'label') {
              contador++;
              if (contador > 1) {
                this.steps.splice(i, 0, { label: data.name, fields: [] })
              } else {

                this.steps = [{ label: data.name, fields: [] }]
              }

            } else {
              //console.log("data", data);
              if (this.steps) {

                if (data.type === 'text' && data.id === 'placavehiculo') {
                  this.steps[contador - 1]['fields'].push({
                    key: data.id, type: "input", templateOptions: {
                      label: data.name, required: true, disabled: true, attributes: { style: "text-transform: lowercase;" }
                      , keyup: (field, event) => {
                        if (field.formControl.value) {
                          let value = field.formControl.value.replace(/[^a-zA-Z0-9]+/, '');
                          field.formControl.setValue(value);
                        }
                      }
                    }
                  });
                } else if (data.type === 'text') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true, disabled: true } });
                } else if (data.type === 'number') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true, type: data.type, disabled: true } });
                } else if (data.type === 'select') {
                  const dataSelect: any[] = data.enum.map(element => element);
                  const dataSelectMap = dataSelect.map(data => {
                    return { label: data, value: data }
                  });
                  this.steps[contador - 1]['fields'].push({
                    key: data.id, type: "select", templateOptions: {
                      label: data.name, required: true, options: dataSelectMap, disabled: true
                    }
                  });
                } else if (data.type === 'date') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true, type: data.type, disabled: true } });
                }


              }

            }



          } else {
            if (data.type === 'separator' || data.type === 'button') {
              return;
            }

            if (data.type === 'foto' || data.type === 'file' || data.type === 'image') {
              this.steps[contador - 1]['fields'].push({ key: data.id, type: "file", wrappers: ['form-field'], templateOptions: { label: data.name, required: true, type: 'file' } });
            }

            if (data.type === 'label') {
              contador++;
              if (contador > 1) {
                this.steps.splice(i, 0, { label: data.name, fields: [] })
              } else {

                this.steps = [{ label: data.name, fields: [] }]
              }

            } else {
              //console.log("data", data);
              if (this.steps) {

                if (data.type === 'text' && data.id === 'placavehiculo') {
                  this.steps[contador - 1]['fields'].push({
                    key: data.id, type: "input", templateOptions: {
                      label: data.name, required: true, attributes: { style: "text-transform: lowercase;" }
                      , keyup: (field, event) => {
                        if (field.formControl.value) {
                          let value = field.formControl.value.replace(/[^a-zA-Z0-9]+/, '');
                          field.formControl.setValue(value);
                        }
                      }
                    }
                  });
                } else if (data.type === 'text') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true } });
                } else if (data.type === 'number') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true, type: data.type } });
                } else if (data.type === 'select') {
                  const dataSelect: any[] = data.enum.map(element => element);
                  const dataSelectMap = dataSelect.map(data => {
                    return { label: data, value: data }
                  });
                  this.steps[contador - 1]['fields'].push({
                    key: data.id, type: "select", templateOptions: {
                      label: data.name, required: true, options: dataSelectMap
                    }
                  });
                } else if (data.type === 'date') {
                  this.steps[contador - 1]['fields'].push({ key: data.id, type: "input", templateOptions: { label: data.name, required: true, type: data.type } });
                }


              }

            }
          }




        });

        //console.log("this.steps 22222", this.steps);
        //console.log("entra aca this steps", this.steps);
        this.form = new FormArray(this.steps.map(() => new FormGroup({})));
        //console.log("this form", this.form);
        this.options = this.steps.map(() => <FormlyFormOptions>{});

        //console.log("this.form", this.form);
        //console.log("this.options", this.options);



      });
      this.spinner.hide('loader');
    }, 500);
  }

  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
  }

  submit() {
    this.sendForm();
    // this.form.reset()
    // this.resetAll();

  }

  resetAll() {
    this.options.forEach(option => option.resetModel());
  }

  async sendForm() {

    //console.log("array images", this.arrayImage);

    let dataModel: any[] = [];
    let arrayData: any[] = [];
    let arrayBuffer: any[] = [];
    let arrayNameFkey: any[] = [];
    let buffer = null;

    this.spinner.show('sendForm');
    //console.log("this.model", this.model);
    dataModel.push(this.model);
    //console.log("data model", dataModel);

    for (let prop in this.model) {
      if (this.model.hasOwnProperty(prop)) {
        let innerObj = {};
        innerObj[prop] = this.model[prop];
        arrayData.push(innerObj)
      }
    }
    //console.log("array data?", arrayData);

    for (let key in arrayData) {
      if (arrayData.hasOwnProperty(key)) {
        let dato: any = Object.values(arrayData[key]);
        if (typeof dato[0] === 'string') {
          if (dato[0].indexOf('fakepath') > -1) {
            //console.log("name", name);
            buffer = null;
            let keyT = JSON.stringify(arrayData[key]);
            let keyV = keyT.split(":");
            let fKey = keyV[0].replace(/['".*+\-?^${}()|[\]\\]+/g, "");
            //console.log("fKey antes", fKey)
            if (fKey) {
              let result = this.arrayImage.find(element => element.key === fKey);
              buffer = new Buffer(result['result'].replace(/^data:image\/\w+;base64,/, ""), 'base64');
              //console.log("buffer", buffer);
              let name = this.dataUser['company_id'] + "/" + this.dataUser['username'].toUpperCase() + "-IMG_" + this._utils.dateImg();
              arrayBuffer.push({ fKey, name, buffer });
              arrayNameFkey.push({ fKey, name });

              // }
              // const reader = new FileReader();
              // reader.onload = () => {
              // let result = reader.result as string;
              // console.log("result", result);

              // this._amazonService.uploadBucket(name, buffer).then((res) => {
              //   console.log("res upload bucket", res);
              // });
              // let buffer = new Buffer(data.replace(/^data:image\/\w+;base64,/, ""), 'base64');
              // }
            }

          }

        }

      }

    }
    //console.log("this modal change?", this.model);
    //console.log("this array data two", this.arrayDataTwo);
    //console.log("this data key and url", this.dataKeyAndUrl);

    let isUpload = await this.uploadBucket(arrayBuffer);

    // for (let prop in this.model) {
    //   if (this.model.hasOwnProperty(prop)) {
    //     this.arrayDataTwo.forEach((item) => {
    //       Object.keys(item).forEach((key) => {
    //         console.log("key:" + key + "value:" + item[key]);
    //       });
    //     });
    //     for (let arrDataTwo in this.arrayDataTwo) {
    //       console.log("arrayDataTwo", this.arrayDataTwo[arrDataTwo]);
    //       let dataArrayTwo: string = (this.arrayDataTwo[arrDataTwo]);
    //       let splitArray = dataArrayTwo.split(":");

    //     }
    //   }
    // }

    // let dataSendReport = {
    //   input: {
    //     company: this.dataUser['company'].Id,
    //     formName: this.dataAll[0].name,
    //     formId: this.dataAll[0].Id,
    //     paramsData
    //   }
    // }

    // Object.keys(arrayData).forEach((key) => {

    // });

    // for (let key of Object.keys(this.model)) {
    //   console.log(key + ":" + this.model[key])
    //   if (typeof this.model[key] === 'string') {
    //     let data: string = this.model[key];
    //     console.log("data", data);
    //     if (data.indexOf('fakepath') > -1) {
    //       let newData = data.split("\\");
    //       console.log("the new model key => ", data[data.length-1]);
    //       this.model[key] = data[data.length-1];
    //     }
    //   }
    //   console.log("type of " + key + this.model[key] + " is " + typeof this.model[key]);
    // }
    // console.log("the modal?", this.model);



  }

  resetStepper() {
    this.stepper.reset()
  }


  navigateBack() {
    this.router.navigateByUrl('/landing/home');
  }

  closeSesion() {

    this._notify.closeSesion("Vuelve pronto", 2000);
    setTimeout(() => {
      localStorage.removeItem("azureToken");
      localStorage.removeItem("last_user_historic");
      localStorage.removeItem("graphToken");
      localStorage.removeItem("actualPage");
      localStorage.removeItem("dynamo_user");
      localStorage.removeItem("evolucion_user");
      localStorage.removeItem("auth");
      localStorage.removeItem("EmpresaNombre");
      localStorage.removeItem("Id");
      localStorage.removeItem("Empresa");
      localStorage.removeItem("TZ");
      localStorage.removeItem("Alias");
      localStorage.removeItem("Placa");
      localStorage.removeItem("GrupoNombre");
      this.router.navigate(["login"]);
    }, 2500);

  }



  async compareData(data) {

    this.arrayOfModal = [];
    //console.log("data compare", data);

    if (data != null) {
      if (data.length > 0) {
        //console.log("entra en data");
        let keyArray: string;

        //console.log("data model 01", this.model);

        for (let itemArrayTwo of this.arrayDataTwo) {

          //console.log("iterable array data", this.arrayOfModal);
          if (itemArrayTwo['type'] === 'separator' || itemArrayTwo['type'] === 'label') {

            this.arrayOfModal.push({ Tipo: itemArrayTwo['type'], name: itemArrayTwo['name'], id: itemArrayTwo['id'], value: itemArrayTwo['name'] });
            continue;
          }

          Object.keys(data).find(key => {
            //console.log(data[key]['fKey']);
            if (data[key]['fKey'] === itemArrayTwo['id']) {
              keyArray = data[key];
            }
          });

          //console.log("keyArray", keyArray);
          if (data) {
            if (keyArray) {
              if (keyArray['fKey'] === itemArrayTwo['id']) {
                this.arrayOfModal.push({ Tipo: itemArrayTwo['type'], name: itemArrayTwo['name'], id: itemArrayTwo['id'], value: keyArray['property'] });
              }
            } else {
              if (itemArrayTwo['type'] !== 'separator' || itemArrayTwo['type'] !== 'label') {
                //console.log("this modal", this.model);
                if (this.model.hasOwnProperty(itemArrayTwo['id'])) {
                  //console.log("itemArrayTwo", itemArrayTwo);
                  if (itemArrayTwo['id'] === 'placavehiculo') {
                    let value: string = this.model[itemArrayTwo['id']];
                    //console.log("value", value);
                    let valuePlaca: string = value.toLowerCase();
                    let valuePlacaT: string = valuePlaca.trim();
                    this.arrayOfModal.push({ Tipo: itemArrayTwo['type'], name: itemArrayTwo['name'], id: itemArrayTwo['id'], value: valuePlacaT });
                  } else if (typeof this.model[itemArrayTwo['id']] === 'string') {
                    let dataValue: string;
                    let dataModel: string = this.model[itemArrayTwo['id']];
                    dataValue = dataModel.trim();
                    if (dataValue) {
                      this.arrayOfModal.push({ Tipo: itemArrayTwo['type'], name: itemArrayTwo['name'], id: itemArrayTwo['id'], value: dataValue });
                    }
                  } else {
                    this.arrayOfModal.push({ Tipo: itemArrayTwo['type'], name: itemArrayTwo['name'], id: itemArrayTwo['id'], value: this.model[itemArrayTwo['id']] });
                  }
                }
              }
            }

            // for (var i = 0; i < this.model.length; i++) {
            //   for (key in this.model[i]) {
            //     if (this.model[i][key].indexOf(keyArray) != -1) {
            //     }
            //   }
            // }

          }



        }



        //console.log("array modal final", this.arrayOfModal);
        this.arrayOfModal.unshift(
          {
            FrTipo: "undefined",
            FrId: "undefined",
          });


        //console.log("data user position", this.dataUserPosition);

        let dataForCreate = {
          appVersion: "40.0.9",
          resource: this.arrayOfModal
        };
        // let objectResult = Object.assign({}, this.arrayOfModal);

        let dataCreateForm = {
          input: {
            company: this.dataUser['company_id'],
            formName: this.dataAll['name'],
            formId: this.dataAll['Id'],
            paramsData: JSON.stringify(dataForCreate),
            userId: this.dataUser['username'],
            type: this.dataAll['type'],
            latitude: this.dataUserPosition.latitude,
            longitude: this.dataUserPosition.longitude
          }
        }



        //console.log("dataForCreate", dataForCreate);
        //console.log("objectResult", this.arrayOfModal);
        //console.log("dataCreateForm", dataCreateForm);


        this._chimeService.CreateFormData(dataCreateForm).then(res => {
          //console.log("res", res);
          this.spinner.hide('sendForm');
          this.stepper.reset();
          this.arrayOfModal = [];
          Swal.fire({
            icon: 'success',
            title: 'Exito',
            text: 'El formulario se ha enviado satisfactoriamente!',
          });
        }).catch(err => {
          console.error(err);
          this.spinner.hide('sendForm');
          this.stepper.reset();
          this.arrayOfModal = [];
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error enviando el formulario, por favor intenta nuevamente',
          });
          this.spinner.hide('sendForm');
        });

      }



    }



  }

  async uploadBucket(array: any[]) {

    //console.log("data que llega array", array);

    let data: any[] = [];
    let responsePromise;

    for (const item of array) {


      let element = item;
      let name = element['name'];

      let buffer = element['buffer'];
      let fKey = element['fKey'];


      // OK THIS
      responsePromise = this._amazonService.uploadBucket(name, buffer, fKey);
      data.push(responsePromise);



    }

    await Promise.all(data).then(res => {
      //console.log("data?", data);
      //console.log("res", res);
      this.compareData(res);
    }).catch(err => {
      console.error(err);
      this.spinner.hide('sendForm');
      this.stepper.reset();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Error enviando las imagenes, por favor intenta nuevamente',
      });
      // this.compareData(null);
    });

    this.spinner.hide();

    // await Promise.all(data).then(res => {
    //   console.log("data?", data);
    //   this.spinner.hide();
    //   console.log("res", res);
    //   // this.compareData(res);


    // }).catch(err => {
    //   this.spinner.hide();
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Oops...',
    //     text: 'Something went wrong!',
    //   });
    //   this.compareData(null);
    // });




  }

}
