import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdministrationService } from '../../../services/administration.service';
import { APIService } from '../../../API.service.service';
import { Router, NavigationEnd } from '@angular/router';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalCreateChimeGroupComponent } from '../modal-create-chime-group/modal-create-chime-group.component';
import { ChimeService } from '../../../services/chime.service';
import { UtilsService } from '../../../services/utils.service';
import { FileSaverService } from 'ngx-filesaver';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AmazonService } from '../../../services/amazon.service';
import { PermissionsService } from './../../../services/permissions.service';

import Swal from 'sweetalert2'
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-list-user-sessions',
  templateUrl: './list-user-sessions.component.html',
  styleUrls: ['./list-user-sessions.component.css'],
  providers: [FileSaverService]
})
export class ListUserSessionsComponent implements OnInit {

  @Input() validatePage: string;
  dynamo_user = JSON.parse(localStorage.getItem("dynamo_user"));
  notFound: any;
  loader: any;
  users = []
  usersDataForModal: any[] = [];
  chimeGroups = [];
  recordings: any = [];
  dataPermission = {};
  showVar: boolean = false;
  _value: number = 0;

  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  tokenData = localStorage.getItem("auth");
  activeUser: boolean = false;
  placa: string;
  type: string;


  inCreateModal = false;

  conferenceName = "";

  modalOption: NgbModalOptions = {}; // not null!

  user = JSON.parse(localStorage.getItem("dynamo_user"));

  httpOptions = {
    headers: new HttpHeaders({
      // 'Content-Type': 'video/mp4',
      'Access-Control-Allow-Origin': '*'
    })
  };

  constructor(
    public _admin: AdministrationService,
    public _chimeService: ChimeService,
    public api: APIService,
    private modalService: NgbModal,
    private _route: Router,
    private _util: UtilsService,
    private fileSaverService: FileSaverService,
    private _amazonService: AmazonService,
    private valuePermissions: PermissionsService,
    router: Router

  ) {

    //console.warn("??????????????????")

    var _prev = localStorage.getItem("previousVideocall");

    if (_prev) {

      var prev = JSON.parse(_prev);


      var newRes = prev;
      newRes.enterprise = this.user.company_id
      newRes.isFromPrevious = true;

      this._route.navigate(['/landing/videocall', prev.Id], { queryParams: prev.params });
    }


    /*
        router.events
        .pipe(filter(event => event instanceof NavigationEnd))
        .subscribe((event: NavigationEnd) => {
          console.log('prevVVVVVV:', event.url);
      
        });
        */

  }

  ngOnInit() {
    this.dataPermission = this.valuePermissions.getSpecificModule('VideoLi');
  }

  ngOnChanges() {

    this.getUsers();


  }



  addChimeGroup() {



    //console.log("user,", this.user);


    const query = {

      input: {
        name: this.conferenceName,
        userId: this.user.id,
        company: this.user.company_id,
        users: []
      }

    };


    this._chimeService.createChimeGroup(query).then((res) => {
      //console.log("resss!", res);

      this.inCreateModal = false;


      res.isFromPrevious = false;
      res.showMenuConfig = true;

      this._route.navigate(['/landing/videocall', res.Id], { queryParams: res });

    })
      .catch((err) => {

        this.inCreateModal = false;

        //console.log("error creating chime group", err);
      })



  }

  onFloatingButtonClick() {
    this.inCreateModal = !this.inCreateModal;
  }


  openModal() {
    //console.log("click");
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    const modalRef = this.modalService.open(ModalCreateChimeGroupComponent, this.modalOption);
    modalRef.componentInstance.id = 10; // should be the id

    modalRef.result.then((result) => {
      //console.log(result);
    }).catch((error) => {
      //console.log(error);
    });
  }

  prueba(chime) {
    //console.log("prueba");
    //console.log("prueba chime", chime);
    // this._route.navigateByUrl('landing/videocall/view');
    this.showVar = true;
  }


  onSuccess(event) {
    //console.log("event??", event);
  }



  onErr(event) {
    //console.log("error event??", event);
  }

  onDown(type: string, fromRemote: boolean, record, event) {
    const aux: string = '.s3.amazonaws.com';
    // const fileName = `bascula.${type}`;

    let recordUrl: string = record.url;
    let recordSplit: string[] = recordUrl.split("/");
    let urlChime = recordSplit[0];
    recordSplit.shift();
    let reJoin = recordSplit.join("/");

    const fileName = `https://${urlChime}${aux}/${reJoin}`;
    3


    if (fromRemote) {

      this._chimeService.download(fileName).subscribe((res) => {
        //console.log("res", res);
        if (res['loaded'] && res['total']) {
          this.value = Math.round(res['loaded'] / res['total'] * 100);
        }

        if (res['body']) {
          this.fileSaverService.save(res['body'], fileName);
          // Math.abs((this.value) * -1)
          let timerInterval
          Swal.fire({
            title: 'Descargando...',
            html: '',
            timer: Math.abs((this.value) * -1),
            timerProgressBar: true,
            willOpen: () => {
              Swal.showLoading()
              timerInterval = setInterval(() => {
                const content = Swal.getContent()
                if (content) {
                  const b = content.querySelector('b')
                  if (b) {
                    Swal.getTimerLeft()
                  }
                }
              }, 100)
            },
            willClose: () => {
              clearInterval(timerInterval)
            }
          }).then((result) => {
            this.value = 0;
            Swal.fire(
              'Completado!',
              'El archivo se ha descargado satisfactoriamente.',
              'success'
            )
          })



        }

      });



      // this._http.get(`https://upload.wikimedia.org/wikipedia/commons/transcoded/c/c0/Big_Buck_Bunny_4K.webm/Big_Buck_Bunny_4K.webm.360p.vp9.webm`, {
      //   observe: 'response',
      //   responseType: 'blob',
      // }).subscribe(res => {
      //   console.log("res", res);
      //   this.fileSaverService.save(res.body, fileName);
      // });

      return;
    }
    // const fileType = this.fileSaverService.genType(fileName);
    // const txtBlob = new Blob([this.text], { type: fileType });
    // this.fileSaverService.save(txtBlob, fileName);
  }


  getUsers() {
    this.users = [];
    let status = JSON.parse(localStorage.getItem("statusInactiveUsers"));
    if (status === 'tab2') {
      this.loader = true;
      const queryGetSessions = {

        company: this.user.company_id,
      }


      this._chimeService.getSessions(queryGetSessions).then((res) => {
        //console.log("res getSessions", res)
        var newData = []

        res.map(item => {
          // if (item.status == validate && item.Id != this.dynamo_user.Id) {
          newData.push(item);
          // }
        })
        this.users = newData;
        //console.log("this.users", this.users);
        this.users.forEach((element) => {
          this.usersDataForModal.push({
            emailUser: element.userId
          });
        });
        //console.log("aqui se envia la data");
        this._util.dataForArrayList.next(this.usersDataForModal);
        //console.log("aqui se envia la data", this.usersDataForModal);

        setTimeout(() => {

          this.loader = false;
          if (this.users.length == 0) {
            this.notFound = true;
          } else { this.notFound = false }


        }, 1500);
      }, err => {

        //console.log("err", err)

        this.loader = false;
        this.notFound = true;


      })

    } else if (status === 'tab1') {
      this.loader = true;
      this.recordings = [];


      const queryChimeGroups = {
        company: this.user.company_id,
      }


      this._chimeService.getChimeGroups(queryChimeGroups).then(async (res) => {

        let dataUser = {
          Id: this.user.id,
          company: this.user.company_name
        }

        this._chimeService.getUser(dataUser).then(async dataUser => {
          //console.log("data user", await dataUser);
          const type = JSON.parse(await dataUser[0].role);
          if (await dataUser[0].placa) {
            localStorage.setItem("placa", dataUser[0].placa);
            this.placa = dataUser[0].placa;
            localStorage.setItem("type", type.type.toLowerCase());
            this.type = type.type;
            this.activeUser = true;



            //console.log("res getChimeGroups", res)
            var newData = []

            res.map(item => {
              item.showMenuConfig = true;
              if (this.activeUser) {
                if (this.placa === item.name) {
                  newData.push(item);
                }
              }
              // if (item.status == validate && item.Id != this.dynamo_user.Id) {
              // }
              if (localStorage.getItem("type") === "principal" ||
                localStorage.getItem("type") === "contact center" ||
                localStorage.getItem("type") === "perito" ||
                localStorage.getItem("type") === "admin" ||
                localStorage.getItem("type") === "administrador" ||
                localStorage.getItem("type") === "client") {
                newData.push(item);

              } else {
                if (this.type === "principal" || this.type === "contact center"
                  || this.type === "perito" || this.type === "admin" || this.type === "administrador" ||
                  this.type === "client") {
                  newData.push(item);
                }
              }

            });

            this.chimeGroups = newData;
            //console.log("this.chimegroups", this.chimeGroups);


            setTimeout(() => {

              this.loader = false;
              if (this.chimeGroups.length == 0) {
                this.notFound = true;
              } else { this.notFound = false }


            }, 1500);

          }

          //console.log("res getChimeGroups", res)
          var newData = []

          res.map(item => {
            item.showMenuConfig = true;
            if (this.activeUser) {
              if (this.placa === item.name) {
                newData.push(item);
              }
            }
            // if (item.status == validate && item.Id != this.dynamo_user.Id) {
            // }
            if (localStorage.getItem("type") === "principal" ||
              localStorage.getItem("type") === "contact center" ||
              localStorage.getItem("type") === "perito" ||
              localStorage.getItem("type") === "admin" ||
              localStorage.getItem("type") === "administrador" || 
              localStorage.getItem("type") === "client") {
              newData.push(item);

            } else {
              if (this.type === "principal" || this.type === "contact center"
                || this.type === "perito" || this.type === "admin" || this.type === "administrador" ||
                this.type === "client") {
                newData.push(item);
              }
            }

          });

          this.chimeGroups = newData;
          //console.log("this.chimegroups", this.chimeGroups);


          setTimeout(() => {

            this.loader = false;
            if (this.chimeGroups.length == 0) {
              this.notFound = true;
            } else { this.notFound = false }


          }, 1500);
          localStorage.setItem("type", type.type.toLowerCase());
          this.type = type.type.toLowerCase();





        });
      }, err => {

        //console.log("err", err)

        this.loader = false;
        this.notFound = true;


      })


    } else {

      this.loader = true;
      this.recordings = [];


      const queryRecordings = {
        userId: this.user.username,
        company: this.user.company_id,
      }

      //console.log("queryRecordings", queryRecordings);
      this._chimeService.getVideo(queryRecordings).then((res) => {
        var newData = []

        res.map(item => {
          let url: string = item.url;
          let urlName: string[] = url.split("/");
          let urlNamePlaca: string[] = urlName[1].split("-");
          item.name = `${urlNamePlaca[0]} - ${urlName[urlName.length - 1].split("T")[0]}.mp4`;
          newData.push(item);
        })
        this.recordings = newData;
        //console.log("this.recordings", this.recordings);


        setTimeout(() => {

          this.loader = false;
          if (this.recordings.length == 0) {
            this.notFound = true;
          } else { this.notFound = false }


        }, 1500);
      }, err => {

        //console.log("err", err)

        this.loader = false;
        this.notFound = true;


      })

    }


  }

  eliminarGroup(chime, i) {
    //console.log("chime", chime)
    //console.log("click aqui", i)
    let dataDelete = {
      input: {
        Id: chime.Id,
        company: chime.company
      }

    }
    this._chimeService.deleteChimeGroup(dataDelete).then((res) => {
      //console.log("res deleteChimeGroup", res);
    })

    this.getUsers();
  }





}
