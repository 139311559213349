/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import { Observable } from "zen-observable-ts";

export type loginInput = {
  userName: string;
  password?: string | null;
  accessToken?: string | null;
};

export type createSessionsInput = {
  cognitoId: string;
  company: string;
  userId: string;
};

export type deleteSessionsInput = {
  company: string;
  userId: string;
};

export type createFormDataInput = {
  formId: string;
  company: string;
  paramsData: string;
  userId: string;
  type: string;
  latitude?: string | null;
  longitude?: string | null;
  formName: string;
  result?: string | null;
};

export type createFormInput = {
  company: string;
  formName: string;
  paramsData: string;
  userId?: string | null;
  groupId?: string | null;
};

export type createChimeGroupInput = {
  name: string;
  userId: string;
  company: string;
  users?: Array<string> | null;
};

export type updateChimeGroupInput = {
  Id: string;
  company: string;
  name?: string | null;
  users?: Array<string> | null;
};

export type deleteChimeGroupInput = {
  Id: string;
  company: string;
};

export type signUpInput = {
  email?: string | null;
  username?: string | null;
  name?: string | null;
  phone_number?: string | null;
  company?: string | null;
  password?: string | null;
  family_name?: string | null;
  status?: boolean | null;
  group?: string | null;
  device?: string | null;
  role?: string | null;
};

export type signUpInput4 = {
  email?: string | null;
  type?: string | null;
  placa?: string | null;
  username?: string | null;
  name?: string | null;
  phone_number?: string | null;
  company?: string | null;
  password?: string | null;
  family_name?: string | null;
  group?: string | null;
  device?: string | null;
  role?: string | null;
};

export type createReportInput = {
  company: string;
  latitude: string;
  longitude: string;
  date: number;
  gps: string;
  type: string;
  userId: string;
  groupId?: string | null;
  heading?: string | null;
  speed?: string | null;
  address?: string | null;
  deviceInfo?: string | null;
};

export type createReportInput2 = {
  company: string;
  latitude: string;
  longitude: string;
  userDate: number;
  gps: string;
  type: string;
  userId: string;
  groupId?: string | null;
  heading?: string | null;
  speed?: string | null;
  address?: string | null;
  deviceInfo?: string | null;
};

export type insertVideoInput = {
  company: string;
  url: string;
  userId: string;
};

export type LoginMutation = {
  __typename: "ResponseUser";
  body: string | null;
  error: string | null;
  message: string | null;
  status: string;
};

export type CreateSessionsMutation = {
  __typename: "Sessions";
  cognitoId: string | null;
  date: number | null;
  company: string | null;
  name: string | null;
  userId: string | null;
};

export type DeleteSessionsMutation = {
  __typename: "Sessions";
  cognitoId: string | null;
  date: number | null;
  company: string | null;
  name: string | null;
  userId: string | null;
};

export type CreateFormDataMutation = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type CreateFormMutation = {
  __typename: "Form";
  Id: string | null;
  availability: string | null;
  company: string | null;
  creation_date: number | null;
  paramsData: string | null;
  name: string | null;
  type: string | null;
  userId: string | null;
};

export type CreateChimeGroupMutation = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type UpdateChimeGroupMutation = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type DeleteChimeGroupMutation = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type SignUpMutation = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type SignUpNoConfirmTm4Mutation = {
  __typename: "Response";
  body: string | null;
  message: string | null;
  status: string | null;
  error: string | null;
};

export type InsertReportMutation = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type InsertReport2Mutation = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type InsertVideoMutation = {
  __typename: "Videos";
  Id: string | null;
  userId: string | null;
  company: string | null;
  url: string | null;
  dateIso8601: string | null;
  serverDate: number | null;
};

export type Insert3dFileMutation = {
  __typename: "Videos";
  Id: string | null;
  userId: string | null;
  company: string | null;
  url: string | null;
  dateIso8601: string | null;
  serverDate: number | null;
};

export type CreateConversationMutation = {
  __typename: "Conversation";
  // The Conversation's timestamp.
  date: string | null;
  // A unique identifier for the Conversation.
  Id: string;
  // The Conversation's messages.
  messages: {
    __typename: "MessageConnection";
    nextToken: string | null;
  } | null;
  // The Conversation's name.
  name: string;
};

export type CreateMessageMutation = {
  __typename: "Message";
  userId: string;
  content: string;
  conversationId: string;
  date: string | null;
  Id: string;
  isSent: boolean | null;
  recipient: string | null;
  sender: string | null;
};

export type CreateUserConversationsMutation = {
  __typename: "UserConversations";
  associated: Array<{
    __typename: "UserConversations";
    conversationId: string;
    userId: string;
  } | null> | null;
  conversation: {
    __typename: "Conversation";
    // The Conversation's timestamp.
    date: string | null;
    // A unique identifier for the Conversation.
    Id: string;
    // The Conversation's name.
    name: string;
  } | null;
  conversationId: string;
  user: {
    __typename: "User";
    company: string;
    confirmed: boolean | null;
    email: string | null;
    family_name: string | null;
    group: string | null;
    Id: string;
    last_transmition: string | null;
    name: string;
    phone_number: string | null;
    role: string | null;
    status: boolean | null;
    username: string;
    placa: string | null;
  } | null;
  userId: string;
};

export type GetReportsQuery = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type GetReportsUserQuery = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type GetReportsUserLastQuery = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type GetReportsUsersLastQuery = {
  __typename: "Report";
  company: string | null;
  userDate: number | null;
  serverDate: number | null;
  dateIso8601: string | null;
  latitude: string | null;
  longitude: string | null;
  gps: string | null;
  type: string | null;
  userId: string | null;
  groupId: string | null;
  heading: string | null;
  speed: string | null;
  address: string | null;
  deviceInfo: string | null;
};

export type GetFormsQuery = {
  __typename: "Form";
  Id: string | null;
  availability: string | null;
  company: string | null;
  creation_date: number | null;
  paramsData: string | null;
  name: string | null;
  type: string | null;
  userId: string | null;
};

export type GetForms2Query = {
  __typename: "Form";
  Id: string | null;
  availability: string | null;
  company: string | null;
  creation_date: number | null;
  paramsData: string | null;
  name: string | null;
  type: string | null;
  userId: string | null;
};

export type GetFormIdQuery = {
  __typename: "Form";
  Id: string | null;
  availability: string | null;
  company: string | null;
  creation_date: number | null;
  paramsData: string | null;
  name: string | null;
  type: string | null;
  userId: string | null;
};

export type GetConfigurationQuery = {
  __typename: "Configuration";
  Id: string | null;
  serveDate: number | null;
  cnfName: string | null;
  cnfValor: string | null;
  dateIso8601: string | null;
  cnfDescription: string | null;
};

export type GetFormsDataKvQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataKvFechaQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataUserKvQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormDataQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormDataUserQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataUserFechaQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataFechaQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetFormsDataUserIdFechaQuery = {
  __typename: "FormData";
  Id: string | null;
  formId: string | null;
  company: string | null;
  latitude: string | null;
  longitude: string | null;
  formName: string | null;
  type: string | null;
  check: string | null;
  formDate: number | null;
  date: number | null;
  dateIso8601: string | null;
  paramsData: string | null;
  tags: string | null;
  userId: string | null;
  result: string | null;
};

export type GetChimeGroupsQuery = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
  whiteBoard: string | null;
  whiteBoardUrl: string | null;
};

export type GetChimeGroupQuery = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type GetSessionsQuery = {
  __typename: "Sessions";
  cognitoId: string | null;
  date: number | null;
  company: string | null;
  name: string | null;
  userId: string | null;
};

export type GetUsersQuery = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type GetUserQuery = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type GetVideoQuery = {
  __typename: "Videos";
  Id: string | null;
  userId: string | null;
  company: string | null;
  url: string | null;
  dateIso8601: string | null;
  serverDate: number | null;
};

export type GetVideo2Query = {
  __typename: "Videos";
  Id: string | null;
  userId: string | null;
  company: string | null;
  url: string | null;
  dateIso8601: string | null;
  serverDate: number | null;
};

export type Get3dFileQuery = {
  __typename: "Videos";
  Id: string | null;
  userId: string | null;
  company: string | null;
  url: string | null;
  dateIso8601: string | null;
  serverDate: number | null;
};

export type AllMessageQuery = {
  __typename: "Message";
  userId: string;
  content: string;
  conversationId: string;
  date: string | null;
  Id: string;
  isSent: boolean | null;
  recipient: string | null;
  sender: string | null;
};

export type AllMessageConnectionQuery = {
  __typename: "MessageConnection";
  messages: Array<{
    __typename: "Message";
    userId: string;
    content: string;
    conversationId: string;
    date: string | null;
    Id: string;
    isSent: boolean | null;
    recipient: string | null;
    sender: string | null;
  } | null> | null;
  nextToken: string | null;
};

export type AllMessageFromQuery = {
  __typename: "Message";
  userId: string;
  content: string;
  conversationId: string;
  date: string | null;
  Id: string;
  isSent: boolean | null;
  recipient: string | null;
  sender: string | null;
};

export type AllUserQuery = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type MeQuery = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type CheckUserQuery = {
  __typename: "User";
  company: string;
  confirmed: boolean | null;
  email: string | null;
  family_name: string | null;
  group: string | null;
  Id: string;
  last_transmition: string | null;
  name: string;
  phone_number: string | null;
  role: string | null;
  status: boolean | null;
  username: string;
  placa: string | null;
};

export type SubscribeToNewChimeGroupSubscription = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type SubscribeToDeleteChimeGroupSubscription = {
  __typename: "ChimeGroup";
  Id: string | null;
  date: number | null;
  company: string | null;
  userId: string | null;
  users: string | null;
  name: string | null;
};

export type SubscribeToNewSessionsSubscription = {
  __typename: "Sessions";
  cognitoId: string | null;
  date: number | null;
  company: string | null;
  name: string | null;
  userId: string | null;
};

export type SubscribeToDeleteSessionsSubscription = {
  __typename: "Sessions";
  cognitoId: string | null;
  date: number | null;
  company: string | null;
  name: string | null;
  userId: string | null;
};

export type SubscribeToNewMessageSubscription = {
  __typename: "Message";
  userId: string;
  content: string;
  conversationId: string;
  date: string | null;
  Id: string;
  isSent: boolean | null;
  recipient: string | null;
  sender: string | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async Login(input: loginInput): Promise<LoginMutation> {
    const statement = `mutation Login($input: loginInput!) {
        login(input: $input) {
          __typename
          body
          error
          message
          status
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <LoginMutation>response.data.login;
  }
  async CreateSessions(
    input: createSessionsInput
  ): Promise<CreateSessionsMutation> {
    const statement = `mutation CreateSessions($input: createSessionsInput!) {
        createSessions(input: $input) {
          __typename
          cognitoId
          date
          company
          name
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateSessionsMutation>response.data.createSessions;
  }
  async DeleteSessions(
    input: deleteSessionsInput
  ): Promise<DeleteSessionsMutation> {
    const statement = `mutation DeleteSessions($input: deleteSessionsInput!) {
        deleteSessions(input: $input) {
          __typename
          cognitoId
          date
          company
          name
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteSessionsMutation>response.data.deleteSessions;
  }
  async CreateFormData(
    input: createFormDataInput
  ): Promise<CreateFormDataMutation> {
    const statement = `mutation CreateFormData($input: createFormDataInput!) {
        createFormData(input: $input) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormDataMutation>response.data.createFormData;
  }
  async CreateForm(input: createFormInput): Promise<CreateFormMutation> {
    const statement = `mutation CreateForm($input: createFormInput!) {
        createForm(input: $input) {
          __typename
          Id
          availability
          company
          creation_date
          paramsData
          name
          type
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateFormMutation>response.data.createForm;
  }
  async CreateChimeGroup(
    input: createChimeGroupInput
  ): Promise<CreateChimeGroupMutation> {
    const statement = `mutation CreateChimeGroup($input: createChimeGroupInput!) {
        createChimeGroup(input: $input) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateChimeGroupMutation>response.data.createChimeGroup;
  }
  async UpdateChimeGroup(
    input: updateChimeGroupInput
  ): Promise<UpdateChimeGroupMutation> {
    const statement = `mutation UpdateChimeGroup($input: updateChimeGroupInput!) {
        updateChimeGroup(input: $input) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateChimeGroupMutation>response.data.updateChimeGroup;
  }
  async DeleteChimeGroup(
    input: deleteChimeGroupInput
  ): Promise<DeleteChimeGroupMutation> {
    const statement = `mutation DeleteChimeGroup($input: deleteChimeGroupInput!) {
        deleteChimeGroup(input: $input) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteChimeGroupMutation>response.data.deleteChimeGroup;
  }
  async SignUp(input: signUpInput): Promise<SignUpMutation> {
    const statement = `mutation SignUp($input: signUpInput!) {
        signUp(input: $input) {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignUpMutation>response.data.signUp;
  }
  async SignUpNoConfirmTm4(
    input: signUpInput4
  ): Promise<SignUpNoConfirmTm4Mutation> {
    const statement = `mutation SignUpNoConfirmTm4($input: signUpInput4!) {
        signUpNoConfirmTM4(input: $input) {
          __typename
          body
          message
          status
          error
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <SignUpNoConfirmTm4Mutation>response.data.signUpNoConfirmTM4;
  }
  async InsertReport(input: createReportInput): Promise<InsertReportMutation> {
    const statement = `mutation InsertReport($input: createReportInput!) {
        insertReport(input: $input) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InsertReportMutation>response.data.insertReport;
  }
  async InsertReport2(
    input: createReportInput2
  ): Promise<InsertReport2Mutation> {
    const statement = `mutation InsertReport2($input: createReportInput2!) {
        insertReport2(input: $input) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InsertReport2Mutation>response.data.insertReport2;
  }
  async InsertVideo(input: insertVideoInput): Promise<InsertVideoMutation> {
    const statement = `mutation InsertVideo($input: insertVideoInput!) {
        insertVideo(input: $input) {
          __typename
          Id
          userId
          company
          url
          dateIso8601
          serverDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <InsertVideoMutation>response.data.insertVideo;
  }
  async Insert3dFile(input: insertVideoInput): Promise<Insert3dFileMutation> {
    const statement = `mutation Insert3dFile($input: insertVideoInput!) {
        insert3dFile(input: $input) {
          __typename
          Id
          userId
          company
          url
          dateIso8601
          serverDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Insert3dFileMutation>response.data.insert3dFile;
  }
  async CreateConversation(
    Id: string,
    name: string,
    createdAt?: string
  ): Promise<CreateConversationMutation> {
    const statement = `mutation CreateConversation($createdAt: String, $Id: ID!, $name: String!) {
        createConversation(createdAt: $createdAt, Id: $Id, name: $name) {
          __typename
          date
          Id
          messages {
            __typename
            nextToken
          }
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {
      Id,
      name
    };
    if (createdAt) {
      gqlAPIServiceArguments.createdAt = createdAt;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateConversationMutation>response.data.createConversation;
  }
  async CreateMessage(
    conversationId: string,
    date: number,
    Id: string,
    content?: string
  ): Promise<CreateMessageMutation> {
    const statement = `mutation CreateMessage($content: String, $conversationId: ID!, $date: AWSTimestamp!, $Id: ID!) {
        createMessage(content: $content, conversationId: $conversationId, date: $date, Id: $Id) {
          __typename
          userId
          content
          conversationId
          date
          Id
          isSent
          recipient
          sender
        }
      }`;
    const gqlAPIServiceArguments: any = {
      conversationId,
      date,
      Id
    };
    if (content) {
      gqlAPIServiceArguments.content = content;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMessageMutation>response.data.createMessage;
  }
  async CreateUserConversations(
    conversationId: string,
    userId: string
  ): Promise<CreateUserConversationsMutation> {
    const statement = `mutation CreateUserConversations($conversationId: ID!, $userId: ID!) {
        createUserConversations(conversationId: $conversationId, userId: $userId) {
          __typename
          associated {
            __typename
            conversationId
            userId
          }
          conversation {
            __typename
            date
            Id
            name
          }
          conversationId
          user {
            __typename
            company
            confirmed
            email
            family_name
            group
            Id
            last_transmition
            name
            phone_number
            role
            status
            username
            placa
          }
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      conversationId,
      userId
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateUserConversationsMutation>(
      response.data.createUserConversations
    );
  }
  async GetReports(company?: string): Promise<Array<GetReportsQuery>> {
    const statement = `query GetReports($company: String) {
        getReports(company: $company) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetReportsQuery>>response.data.getReports;
  }
  async GetReportsUser(
    userId?: string,
    company?: string
  ): Promise<Array<GetReportsUserQuery>> {
    const statement = `query GetReportsUser($userId: String, $company: String) {
        getReportsUser(userId: $userId, company: $company) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetReportsUserQuery>>response.data.getReportsUser;
  }
  async GetReportsUserLast(
    userId?: string,
    company?: string
  ): Promise<Array<GetReportsUserLastQuery>> {
    const statement = `query GetReportsUserLast($userId: String, $company: String) {
        getReportsUserLast(userId: $userId, company: $company) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetReportsUserLastQuery>>response.data.getReportsUserLast;
  }
  async GetReportsUsersLast(
    company?: string
  ): Promise<Array<GetReportsUsersLastQuery>> {
    const statement = `query GetReportsUsersLast($company: String) {
        getReportsUsersLast(company: $company) {
          __typename
          company
          userDate
          serverDate
          dateIso8601
          latitude
          longitude
          gps
          type
          userId
          groupId
          heading
          speed
          address
          deviceInfo
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetReportsUsersLastQuery>>response.data.getReportsUsersLast;
  }
  async GetForms(Id?: string, company?: string): Promise<Array<GetFormsQuery>> {
    const statement = `query GetForms($Id: String, $company: String) {
        getForms(Id: $Id, company: $company) {
          __typename
          Id
          availability
          company
          creation_date
          paramsData
          name
          type
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsQuery>>response.data.getForms;
  }
  async GetForms2(
    Id?: string,
    company?: string
  ): Promise<Array<GetForms2Query>> {
    const statement = `query GetForms2($Id: String, $company: String) {
        getForms2(Id: $Id, company: $company) {
          __typename
          Id
          availability
          company
          creation_date
          paramsData
          name
          type
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetForms2Query>>response.data.getForms2;
  }
  async GetFormId(
    Id?: string,
    company?: string
  ): Promise<Array<GetFormIdQuery>> {
    const statement = `query GetFormId($Id: String, $company: String) {
        getFormId(Id: $Id, company: $company) {
          __typename
          Id
          availability
          company
          creation_date
          paramsData
          name
          type
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormIdQuery>>response.data.getFormId;
  }
  async GetConfiguration(Id?: string): Promise<Array<GetConfigurationQuery>> {
    const statement = `query GetConfiguration($Id: String) {
        getConfiguration(Id: $Id) {
          __typename
          Id
          serveDate
          cnfName
          cnfValor
          dateIso8601
          cnfDescription
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetConfigurationQuery>>response.data.getConfiguration;
  }
  async GetFormsDataKv(
    formId: string,
    company: string,
    formValue: string,
    formKey?: string
  ): Promise<Array<GetFormsDataKvQuery>> {
    const statement = `query GetFormsDataKv($formId: String!, $company: String!, $formKey: String, $formValue: String!) {
        getFormsDataKV(formId: $formId, company: $company, formKey: $formKey, formValue: $formValue) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formId,
      company,
      formValue
    };
    if (formKey) {
      gqlAPIServiceArguments.formKey = formKey;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataKvQuery>>response.data.getFormsDataKV;
  }
  async GetFormsDataKvFecha(
    formId: string,
    company: string,
    formValue: string,
    Inicio: number,
    Fin: number,
    formKey?: string
  ): Promise<Array<GetFormsDataKvFechaQuery>> {
    const statement = `query GetFormsDataKvFecha($formId: String!, $company: String!, $formKey: String, $formValue: String!, $Inicio: AWSTimestamp!, $Fin: AWSTimestamp!) {
        getFormsDataKVFecha(formId: $formId, company: $company, formKey: $formKey, formValue: $formValue, Inicio: $Inicio, Fin: $Fin) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formId,
      company,
      formValue,
      Inicio,
      Fin
    };
    if (formKey) {
      gqlAPIServiceArguments.formKey = formKey;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataKvFechaQuery>>response.data.getFormsDataKVFecha;
  }
  async GetFormsDataUserKv(
    formId: string,
    company: string,
    userId: string,
    formValue: string,
    formKey?: string
  ): Promise<Array<GetFormsDataUserKvQuery>> {
    const statement = `query GetFormsDataUserKv($formId: String!, $company: String!, $userId: String!, $formKey: String, $formValue: String!) {
        getFormsDataUserKV(formId: $formId, company: $company, userId: $userId, formKey: $formKey, formValue: $formValue) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      formId,
      company,
      userId,
      formValue
    };
    if (formKey) {
      gqlAPIServiceArguments.formKey = formKey;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataUserKvQuery>>response.data.getFormsDataUserKV;
  }
  async GetFormsData(
    formId?: string,
    company?: string
  ): Promise<Array<GetFormsDataQuery>> {
    const statement = `query GetFormsData($formId: String, $company: String) {
        getFormsData(formId: $formId, company: $company) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (formId) {
      gqlAPIServiceArguments.formId = formId;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataQuery>>response.data.getFormsData;
  }
  async GetFormData(
    Id?: string,
    company?: string
  ): Promise<Array<GetFormDataQuery>> {
    const statement = `query GetFormData($Id: String, $company: String) {
        getFormData(Id: $Id, company: $company) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormDataQuery>>response.data.getFormData;
  }
  async GetFormDataUser(
    userId?: string,
    company?: string
  ): Promise<Array<GetFormDataUserQuery>> {
    const statement = `query GetFormDataUser($userId: String, $company: String) {
        getFormDataUser(userId: $userId, company: $company) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormDataUserQuery>>response.data.getFormDataUser;
  }
  async GetFormsDataUserFecha(
    userId: string,
    company: string,
    Inicio: number,
    Fin: number
  ): Promise<Array<GetFormsDataUserFechaQuery>> {
    const statement = `query GetFormsDataUserFecha($userId: String!, $company: String!, $Inicio: AWSTimestamp!, $Fin: AWSTimestamp!) {
        getFormsDataUserFecha(userId: $userId, company: $company, Inicio: $Inicio, Fin: $Fin) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      company,
      Inicio,
      Fin
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataUserFechaQuery>>(
      response.data.getFormsDataUserFecha
    );
  }
  async GetFormsDataFecha(
    company: string,
    formId: string,
    Inicio: number,
    Fin: number
  ): Promise<Array<GetFormsDataFechaQuery>> {
    const statement = `query GetFormsDataFecha($company: String!, $formId: String!, $Inicio: AWSTimestamp!, $Fin: AWSTimestamp!) {
        getFormsDataFecha(company: $company, formId: $formId, Inicio: $Inicio, Fin: $Fin) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      company,
      formId,
      Inicio,
      Fin
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataFechaQuery>>response.data.getFormsDataFecha;
  }
  async GetFormsDataUserIdFecha(
    userId: string,
    company: string,
    Id: string,
    Inicio: number,
    Fin: number
  ): Promise<Array<GetFormsDataUserIdFechaQuery>> {
    const statement = `query GetFormsDataUserIdFecha($userId: String!, $company: String!, $Id: String!, $Inicio: AWSTimestamp!, $Fin: AWSTimestamp!) {
        getFormsDataUserIdFecha(userId: $userId, company: $company, Id: $Id, Inicio: $Inicio, Fin: $Fin) {
          __typename
          Id
          formId
          company
          latitude
          longitude
          formName
          type
          check
          formDate
          date
          dateIso8601
          paramsData
          tags
          userId
          result
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      company,
      Id,
      Inicio,
      Fin
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetFormsDataUserIdFechaQuery>>(
      response.data.getFormsDataUserIdFecha
    );
  }
  async GetChimeGroups(company?: string): Promise<Array<GetChimeGroupsQuery>> {
    const statement = `query GetChimeGroups($company: String) {
        getChimeGroups(company: $company) {
          __typename
          Id
          date
          company
          userId
          users
          name
          whiteBoard
          whiteBoardUrl
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetChimeGroupsQuery>>response.data.getChimeGroups;
  }
  async GetChimeGroup(
    Id?: string,
    company?: string
  ): Promise<Array<GetChimeGroupQuery>> {
    const statement = `query GetChimeGroup($Id: String, $company: String) {
        getChimeGroup(Id: $Id, company: $company) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetChimeGroupQuery>>response.data.getChimeGroup;
  }
  async GetSessions(company?: string): Promise<Array<GetSessionsQuery>> {
    const statement = `query GetSessions($company: String) {
        getSessions(company: $company) {
          __typename
          cognitoId
          date
          company
          name
          userId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetSessionsQuery>>response.data.getSessions;
  }
  async GetUsers(company?: string): Promise<Array<GetUsersQuery>> {
    const statement = `query GetUsers($company: String) {
        getUsers(company: $company) {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetUsersQuery>>response.data.getUsers;
  }
  async GetUser(Id?: string, company?: string): Promise<Array<GetUserQuery>> {
    const statement = `query GetUser($Id: String, $company: String) {
        getUser(Id: $Id, company: $company) {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (Id) {
      gqlAPIServiceArguments.Id = Id;
    }
    if (company) {
      gqlAPIServiceArguments.company = company;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetUserQuery>>response.data.getUser;
  }
  async GetVideo(
    userId: string,
    company: string,
    sortDirection?: string
  ): Promise<Array<GetVideoQuery>> {
    const statement = `query GetVideo($userId: String!, $company: String!, $sortDirection: String) {
        getVideo(userId: $userId, company: $company, sortDirection: $sortDirection) {
          __typename
          Id
          userId
          company
          url
          dateIso8601
          serverDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      company
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetVideoQuery>>response.data.getVideo;
  }
  async GetVideo2(
    userId: string,
    company: string,
    sortDirection?: string
  ): Promise<Array<GetVideo2Query>> {
    const statement = `query GetVideo2($userId: String!, $company: String!, $sortDirection: String) {
        getVideo2(userId: $userId, company: $company, sortDirection: $sortDirection) {
          __typename
          Id
          userId
          company
          url
          dateIso8601
          serverDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      company
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetVideo2Query>>response.data.getVideo2;
  }
  async Get3dFile(
    userId: string,
    company: string,
    sortDirection?: string
  ): Promise<Array<Get3dFileQuery>> {
    const statement = `query Get3dFile($userId: String!, $company: String!, $sortDirection: String) {
        get3dFile(userId: $userId, company: $company, sortDirection: $sortDirection) {
          __typename
          Id
          userId
          company
          url
          dateIso8601
          serverDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      userId,
      company
    };
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<Get3dFileQuery>>response.data.get3dFile;
  }
  async AllMessage(
    conversationId: string,
    after?: string,
    first?: number
  ): Promise<Array<AllMessageQuery>> {
    const statement = `query AllMessage($after: String, $conversationId: ID!, $first: Int) {
        allMessage(after: $after, conversationId: $conversationId, first: $first) {
          __typename
          userId
          content
          conversationId
          date
          Id
          isSent
          recipient
          sender
        }
      }`;
    const gqlAPIServiceArguments: any = {
      conversationId
    };
    if (after) {
      gqlAPIServiceArguments.after = after;
    }
    if (first) {
      gqlAPIServiceArguments.first = first;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<AllMessageQuery>>response.data.allMessage;
  }
  async AllMessageConnection(
    conversationId: string,
    after?: string,
    first?: number
  ): Promise<AllMessageConnectionQuery> {
    const statement = `query AllMessageConnection($after: String, $conversationId: ID!, $first: Int) {
        allMessageConnection(after: $after, conversationId: $conversationId, first: $first) {
          __typename
          messages {
            __typename
            userId
            content
            conversationId
            date
            Id
            isSent
            recipient
            sender
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {
      conversationId
    };
    if (after) {
      gqlAPIServiceArguments.after = after;
    }
    if (first) {
      gqlAPIServiceArguments.first = first;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <AllMessageConnectionQuery>response.data.allMessageConnection;
  }
  async AllMessageFrom(
    conversationId: string,
    sender: string,
    after?: string,
    first?: number
  ): Promise<Array<AllMessageFromQuery>> {
    const statement = `query AllMessageFrom($after: String, $conversationId: ID!, $first: Int, $sender: String!) {
        allMessageFrom(after: $after, conversationId: $conversationId, first: $first, sender: $sender) {
          __typename
          userId
          content
          conversationId
          date
          Id
          isSent
          recipient
          sender
        }
      }`;
    const gqlAPIServiceArguments: any = {
      conversationId,
      sender
    };
    if (after) {
      gqlAPIServiceArguments.after = after;
    }
    if (first) {
      gqlAPIServiceArguments.first = first;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<AllMessageFromQuery>>response.data.allMessageFrom;
  }
  async AllUser(after?: string, first?: number): Promise<Array<AllUserQuery>> {
    const statement = `query AllUser($after: String, $first: Int) {
        allUser(after: $after, first: $first) {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (after) {
      gqlAPIServiceArguments.after = after;
    }
    if (first) {
      gqlAPIServiceArguments.first = first;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<AllUserQuery>>response.data.allUser;
  }
  async Me(): Promise<MeQuery> {
    const statement = `query Me {
        me {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const response = (await API.graphql(graphqlOperation(statement))) as any;
    return <MeQuery>response.data.me;
  }
  async CheckUser(userId?: string): Promise<CheckUserQuery> {
    const statement = `query CheckUser($userId: String) {
        checkUser(userId: $userId) {
          __typename
          company
          confirmed
          email
          family_name
          group
          Id
          last_transmition
          name
          phone_number
          role
          status
          username
          placa
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (userId) {
      gqlAPIServiceArguments.userId = userId;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CheckUserQuery>response.data.checkUser;
  }
  SubscribeToNewChimeGroupListener: Observable<
    SubscribeToNewChimeGroupSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription SubscribeToNewChimeGroup($Id: ID!) {
        subscribeToNewChimeGroup(Id: $Id) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`
    )
  ) as Observable<SubscribeToNewChimeGroupSubscription>;

  SubscribeToDeleteChimeGroupListener: Observable<
    SubscribeToDeleteChimeGroupSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription SubscribeToDeleteChimeGroup($Id: ID!) {
        subscribeToDeleteChimeGroup(Id: $Id) {
          __typename
          Id
          date
          company
          userId
          users
          name
        }
      }`
    )
  ) as Observable<SubscribeToDeleteChimeGroupSubscription>;

  SubscribeToNewSessionsListener: Observable<
    SubscribeToNewSessionsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription SubscribeToNewSessions($Id: ID!) {
        subscribeToNewSessions(Id: $Id) {
          __typename
          cognitoId
          date
          company
          name
          userId
        }
      }`
    )
  ) as Observable<SubscribeToNewSessionsSubscription>;

  SubscribeToDeleteSessionsListener: Observable<
    SubscribeToDeleteSessionsSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription SubscribeToDeleteSessions($Id: ID!) {
        subscribeToDeleteSessions(Id: $Id) {
          __typename
          cognitoId
          date
          company
          name
          userId
        }
      }`
    )
  ) as Observable<SubscribeToDeleteSessionsSubscription>;

  SubscribeToNewMessageListener: Observable<
    SubscribeToNewMessageSubscription
  > = API.graphql(
    graphqlOperation(
      `subscription SubscribeToNewMessage($conversationId: ID!) {
        subscribeToNewMessage(conversationId: $conversationId) {
          __typename
          userId
          content
          conversationId
          date
          Id
          isSent
          recipient
          sender
        }
      }`
    )
  ) as Observable<SubscribeToNewMessageSubscription>;
}
