import { Component, OnInit, OnChanges, KeyValueDiffers } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnChanges {

  actualPage: any;
  size_page: boolean;
  differ: any;

  constructor(public _route: Router,
    public _activatedRoute: ActivatedRoute) {

  }

  ngOnInit() {

    this.validateSession();
    // this.goToPage();
    // this.escucharCambiosRuta();

  }

  ngOnChanges() {
    //console.log("the actual page", this.actualPage);

  }

  validateSession() {
    //console.log("entra al validate session");
    if (localStorage.getItem("dynamo_user")) {
      //console.log("validaresmos")
      this._route.navigate(['/landing/home']);
    } else {
      //console.log("validaresmos false")
      this._route.navigate(['/login']);
    }
  }

  changuePageRecive(event) {
    this.actualPage = event;
    localStorage.setItem('actualPage', event);

  }

  size_page_Recive(event) {
    this.size_page = event;
  }

  goToPage() {
    //console.log("aqui");
    if (localStorage.getItem('actualPage') !== "seeform") {
      let page = localStorage.getItem('actualPage');
      // //console.log("typeof", typeof (page));
      this._route.navigateByUrl(`/landing/${page}`)
    } else {
      // let page = localStorage.getItem('actualPage');
      // console.log("typeof", typeof (page));
      // this._route.navigateByUrl(`/landing/${page}`)
    }
    // if (localStorage.getItem('actualPage') === "seeform") {
    //   console.log("mirame aqui");
    //   let formId: string = localStorage.getItem('frId');
    //   console.log(typeof(formId));
    //   this._route.navigateByUrl(`/landing/forms/viewForm/${formId}`)
    // }
  }

  escucharCambiosRuta() {
    //console.log("escuchas cambios");
    this._route.events.subscribe((val) => {
      // see also 
      if (localStorage.getItem('actualPage') === "frId") {
        let frId = localStorage.getItem('actualPage');
        this._route.navigateByUrl(`/landing/forms/viewForm/${frId}`)
      }
    });
  }

}
