import { Component, OnInit, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NotifyService } from 'src/app/services/notify.service';

@Component({
  selector: 'app-users-sessions',
  templateUrl: './users-sessions.component.html',
  styleUrls: ['./users-sessions.component.css']
})
export class UsersSessionsComponent implements OnInit, OnChanges {

  validatePage: string;
  tittle: string;



  constructor(
    private router: Router,
    private _notify: NotifyService
  ) { }

  ngOnInit() {
    this.validateUsersStatus();
    this.changueUsersStatus(this.validatePage);
    //console.log("title?", this.tittle);

  }

  ngOnChanges() {
    this.changueUsersStatus('');
    //console.log("title?", this.tittle);
  }



  changueUsersStatus(data: string) {
    //console.log("data", data);
    switch (data.length > 0) {
      case data === 'tab1':
        this.validatePage = 'tab1';
        this.tittle = 'reuniones activas';
        break;
    }
    //console.log("change status", this.validatePage);
    localStorage.setItem("statusInactiveUsers", JSON.stringify(this.validatePage));

    // this.updateUsers();


  }


  navigateBack() {
    this.router.navigateByUrl('/landing/home');
  }


  closeSesion() {

    this._notify.closeSesion("Vuelve pronto", 2000);
    setTimeout(() => {
      localStorage.removeItem("azureToken");
      localStorage.removeItem("last_user_historic");
      localStorage.removeItem("graphToken");
      localStorage.removeItem("actualPage");
      localStorage.removeItem("dynamo_user");
      localStorage.removeItem("auth");
      localStorage.removeItem("EmpresaNombre");
      localStorage.removeItem("Id");
      localStorage.removeItem("Empresa");
      localStorage.removeItem("TZ");
      localStorage.removeItem("Alias");
      localStorage.removeItem("Placa");
      localStorage.removeItem("GrupoNombre");
      this.router.navigate(["home-page"]);
    }, 2500);

  }

  validateUsersStatus() {


    if (localStorage.getItem("statusInactiveUsers")) {

      this.validatePage = JSON.parse(localStorage.getItem("statusInactiveUsers"));
      //console.log("the validate", this.validatePage);
    } else {
      this.validatePage = 'tab1';
      localStorage.setItem("statusInactiveUsers", "tab1");
    }
  }

}
