<div class="general-cont box">


    <div class="columns is-vcentered is-mobile is-2-mobile" style="padding: 1%;">

        <div class="column is-6">

            <button type="button" class="btn btn-outline-primary" style="text-transform: none;" (click)="navigateBack()"><i class=" fas
                fa-arrow-circle-left"></i> Regresar
            </button>

        </div>


        <div class="column is-6 has-text-right">
            <button type="button" class="button is-danger" (click)="closeSesion()"><i class="fas fa-power-off mr-1"></i> Salir </button>

        </div>

    </div>

    <div class="columns is-vcentered is-mobile is-1-mobile" style="padding: 1%;">

        <div class="column is-12 has-text-centered">

            <div class="center-up">
                <div>
                    {{tittle | uppercase}}
                </div>
            </div>

        </div>

    </div>




    <div class="tab-cont animated fadeInRight">

        <div class="tabs" id="tabs">
            <ul>
                <li [ngClass]="{ 'is-active' : validatePage === 'tab1'} ">
                    <a (click)="changueUsersStatus('tab1')" id="tab1">Reuniones Activas</a></li>
            </ul>
        </div>

    </div>


    <div class="down-cont">
        <app-list-user-sessions [validatePage]="validatePage">
        </app-list-user-sessions>
    </div>


</div>