<div class="general-cont box">

    <div class="up-cont animated fadeInRight">

        <div class="center-up">
            <div>
                {{tittle | uppercase}}
            </div>
        </div>

    </div>


    <div class="tab-cont animated fadeInRight">

        <div class="tabs" id="tabs">
            <ul>
                <li *ngIf="(dataPermission['read']?.allowed) && (dataPermission['additional']?.tabWhiteboard | typeof) != 'boolean'; else tabWhiteboard"
                    [ngClass]="{ 'is-active' : validatePage === 'tab1'} ">
                    <a (click)="changueUsersStatus('tab1')" id="tab1">Pizarras</a>
                </li>
                <ng-template #tabWhiteboard>
                    <ng-container
                        *ngIf="!(dataPermission['read']?.allowed) || (dataPermission['additional']?.tabWhiteboard)">
                        <li [ngClass]="{ 'is-active' : validatePage === 'tab1'} " class="disabledLink">
                            <a (click)="changueUsersStatus('tab1')" id="tab1">Pizarras</a>
                        </li>
                    </ng-container>

                </ng-template>
            </ul>
        </div>

    </div>


    <div class="down-cont">
        <app-whiteboard-all [validatePage]="validatePage">
        </app-whiteboard-all>
    </div>


</div>