import gql from 'graphql-tag';

export const getCheckUser = gql`query CheckUser($userId: String) {  
  checkUser(userId: $userId) {
    Id
  }
}`
  ;

export const getForms = gql`query GetForms($Id: String, $company: String) {
  getForms(Id: $Id, company: $company) {
    __typename
    Id
    availability
    company
    creation_date
    paramsData
    name
    type
    userId
  }
}
`
  ;

export const getFormId = gql`query GetFormId($company: String, $Id: String) {
  getFormId(company: $company, Id: $Id) {
    __typename
    Id
    availability
    company
    creation_date
    paramsData
    name
    type
    userId
  }
}
`
  ;

export const getFormForSpecificId = gql`query GetForms($Id: String, $company: String) {
  getForms(Id: $Id, company: $company) {
    Id
    company
    name
    type
    userId
    }
  }
  `;

export const createChimeGroup = gql`mutation CreateChimeGroup($input: createChimeGroupInput!) {  
  createChimeGroup(input: $input) {
    __typename
    Id
    date
    company
    userId
    users
    name
  }
}`
  ;

export const getReports = gql`query GetReports($company: String) {  
  getReports(company: $company) {
    __typename
    company
    userDate
    serverDate
    dateIso8601
    latitude
    longitude
    gps
    type
    userId
    groupId
    heading
    speed
    address
    deviceInfo
  }
}`
  ;

export const getReportsUser = gql`query GetReportsUser($userId: String, $company: String) {  
  getReportsUser(userId: $userId, company: $company) {
    __typename
    company
    userDate
    serverDate
    dateIso8601
    latitude
    longitude
    gps
    type
    userId
    groupId
    heading
    speed
    address
    deviceInfo
  }
}`
  ;

export const getReportsUsersLast = gql`query GetReportsUsersLast($company: String,$userId: String) {  
  getReportsUserLast(company: $company, userId: $userId) {
    userId
    gps
    groupId
    address
    dateIso8601
    latitude
    longitude
    userDate
  }
}`
  ;

export const deleteChimeGroup = gql`mutation DeleteChimeGroup($input: deleteChimeGroupInput!) {  
  deleteChimeGroup(input: $input) {
    __typename
    Id
    date
    company
    userId
    users
    name
  }
}`
  ;

export const getUser = gql`query GetUser($Id: String, $company: String) {  
  getUser(Id: $Id, company: $company) {
    __typename
    company
    confirmed
    email
    family_name
    group
    Id
    last_transmition
    name
    phone_number
    role
    status
    username
    placa
  }
}`
  ;

export const getUsers = gql`query GetUsers($company: String) {  
  getUsers(company: $company) {
    __typename
    company
    confirmed
    email
    family_name
    group
    Id
    last_transmition
    name
    phone_number
    role
    status
    username
    placa
  }
}`
  ;

export const getFormsData = gql`query GetFormsData($formId: String, $company: String) {
  getFormsData(formId: $formId, company: $company) {
    __typename
    Id
    formId
    company
    latitude
    longitude
    formName
    type
    check
    date
    dateIso8601
    paramsData
    tags
    userId
    result
  }
}
`
  ;

export const getFormData = gql`query GetFormData($Id: String, $company: String) {
  getFormData(Id: $Id, company: $company) {
    __typename
    Id
    formId
    company
    latitude
    longitude
    formName
    type
    check
    date
    dateIso8601
    paramsData
    tags
    userId
    result
  }
}
`
  ;


export const getSessions = gql`query GetSessions($userId: String, $company: String) {
  getSessions(userId: $userId, company: $company) {
    __typename
    cognitoId
    date
    company
    userId
  }
}
`
  ;

export const getChimeGroup = gql`query GetChimeGroup($Id: String, $company: String) {
  getChimeGroup(Id: $Id, company: $company) {
    __typename
    Id
    date
    company
    users
    name
  }
}
`
  ;

export const getChimeGroups = gql`query GetChimeGroups($company: String) {
  getChimeGroups(company: $company) {
    Id
    date
    company
    userId
    users
    name
    whiteBoard
    whiteBoardUrl
  }
}
`
  ;


export const getVideo = gql`query GetVideo($userId: String!, $company: String!, $sortDirection: String) {
  getVideo(userId: $userId, company: $company, sortDirection: $sortDirection) {
    __typename
    Id
    userId
    company
    url
    serverDate
  }
}
`
  ;


export const create_form = gql`mutation CreateForm(
  $Id: String, 
  $availability: AWSJSON, 
  $company: String, 
  $form_name: String,
  $creation_date: AWSTimestamp, 
  $paramsData: AWSJSON
  ) {  
    createForm(
      Id: $Id, availability: $availability,
      company: $company,
      form_name: $form_name, 
      creation_date: $creation_date,
      paramsData: $paramsData
  ) {
    __typename
    Id
    availability
    company
    creation_date
    paramsData
    form_name
  }
}`
  ;


export const createFormData = gql`mutation CreateFormData($input: createFormDataInput!) {  
  createFormData(input: $input) {
    __typename
    Id
    formId
    company
    latitude
    longitude
    formName
    type
    check
    date
    dateIso8601
    paramsData
    tags
    userId
    result
  }
}`
  ;

//------------------------------------------------------------------------

export const ALL_FORMS = gql`
query formas($FrId:Int,$FrEmpresa: Int){
  formas(FrId:$FrId, FrEmpresa: $FrEmpresa) {
    FrId
    FrMovil
    FrFechaInicio
    FrLlegada
    FrFechaFin
    FrCiudad
    FrDireccion
    FrDatos
    FrLatitud
    FrLongitud
    FrUsuario
    FrResultado
    FrEstado
    FrCheck
    FrTipo
    FrEmpresa
  }
}
`;

export const GET_PARADAS = gql`
query paradas($PrEmpresa:Int,$PrDireccion:String,$PrMovil:String,$PrCampoId:String,$PrCampoValor:String,$Inicio:String,$Fin:String){
  paradas(PrEmpresa:$PrEmpresa, PrDireccion:$PrDireccion,PrMovil:$PrMovil, PrCampoId:$PrCampoId, PrCampoValor:$PrCampoValor,Inicio:$Inicio,Fin:$Fin) {
    PrId
    PrMovil
    PrFechaInicio
    PrDatos
    PrEmpresa
    PrLatitud
    PrLongitud
    PrTipo
  }
}
`;

export const GET_PARADAS_CURSOR = gql`
query paradas_cursor($first: Int, $PrEmpresa: Int, $PrDireccion: String, 
  $PrMovil: String, $PrCampoId: String, $PrCampoValor: String, $Inicio: String, $Fin: String,
  $after: String, $before: String, $PrEstados: Int, $PrTipo: Int){
    
  paradas_cursor(first:$first, PrEmpresa: $PrEmpresa, 
  PrDireccion:$PrDireccion, PrMovil: $PrMovil, PrCampoId: $PrCampoId, 
    PrCampoValor: $PrCampoValor, Inicio: $Inicio, Fin: $Fin 
    after: $after, before: $before, PrEstados: $PrEstados,PrTipo: $PrTipo){
      pageInfo {
  	  totalCount
  	  hasNextPage
  	  hasPreviousPage
  	}
    edges {
      cursor
      node { 
        PrId
        PrMovil
        PrFechaInicio
        PrFechaFin
        PrDireccion
        PrDatos
        PrLatitud
        PrLongitud
        PrUsuario
        PrTipo
        PrCheck
        PrEmpresa
      }
    }
  }
}
`;

export const UPDATE_PARADA = gql`
mutation($PrId: Int, $PrDatos: String, $UserConnect: String, $PrTipo: Int, $PrCheck: Boolean, $PrEmpresa: Int!){
	updateParada(PrId: $PrId, PrDatos: $PrDatos, UserConnect: $UserConnect, PrTipo: $PrTipo, PrCheck: $PrCheck, PrEmpresa: $PrEmpresa){
	  PrId
	  PrAz
	  PrEstados
	  PrMovil
	  PrFechaInicio
	  PrLlegada
	  PrFechaFin
	  PrCiudad
	  PrDireccion
	  PrDatos
	  PrLatitud
	  PrLongitud
	  PrUsuario
	  PrResultado
	  PrEstado
	  PrTipo
	  PrCheck
	  PrEmpresa
	}
}
`;

export const NEW_FORM = gql`
mutation($FrMovil: String,$FrFechaInicio: String, $FrDireccion: String, $FrDatos: String, $FrUsuario: String, $FrTipo: Int, $FrEmpresa: Int){
  addForma(FrMovil: $FrMovil,FrFechaInicio: $FrFechaInicio,FrDireccion: $FrDireccion,FrDatos: $FrDatos, FrUsuario: $FrUsuario, FrTipo: $FrTipo,FrEmpresa: $FrEmpresa) {
    FrId
    FrMovil
    FrFechaInicio
    FrLlegada
    FrFechaFin
    FrCiudad
    FrDireccion
    FrDatos
    FrLatitud
    FrLongitud
    FrUsuario
    FrResultado
    FrEstado
    FrCheck
    FrTipo
    FrEmpresa
  }
}
`;

export const UPDATE_FORM = gql`
mutation ($FrId:Int,$FrDireccion:String,$FrDatos:String,$FrMovil: String) {
	updateForma(FrId:$FrId, FrDireccion:$FrDireccion, FrDatos:$FrDatos, FrMovil: $FrMovil) {
	  FrId
	  FrMovil
	  FrFechaInicio
	  FrLlegada
	  FrFechaFin
	  FrCiudad
	  FrDireccion
	  FrDatos
	  FrLatitud
	  FrLongitud
	  FrUsuario
	  FrResultado
	  FrEstado
	  FrCheck
	  FrTipo
	  FrEmpresa
	}
}
`;

export const insertVideo = gql`mutation InsertVideo($input: insertVideoInput!) {  
  insertVideo(input: $input) {
    __typename
    Id
    company
    serverDate
    url
    userId
  }
}`
  ;

export const DELETE_FORM = gql`
mutation deleteForma ($FrId:Int, $FrEmpresa:Int, $FrMovil:String) {
   deleteForma (FrId:$FrId, FrEmpresa: $FrEmpresa, FrMovil:$FrMovil){
    FrId
    FrMovil
    FrEmpresa
   }
}
`;